import { all, fork } from 'redux-saga/effects'
import { watchAuthSaga } from '../features/authComponent/authSaga';
import { watchCompaniesSaga } from '../features/companiesComponent/companiesSaga';
import { watchCreateCVSagaSaga } from '../features/createCVComponent/createCVSaga';
import { watchCvsSaga } from '../features/cvsComponent/CVsSaga';
import { watchEditCvSaga } from '../features/editCvComponent/editCvSaga';
import { watchAdminsSaga } from '../features/adminsComponent/adminsSaga';
import { watchPackagesSaga } from '../features/packagesComponent/packagesSaga';

export default function* rootSaga() {
  yield all([
    watchAuthSaga,
    watchCompaniesSaga,
    watchCreateCVSagaSaga,
    watchCvsSaga,
    watchEditCvSaga,
    watchAdminsSaga,
    watchPackagesSaga,
    // more sagas from different files
  ].map(fork));
}
