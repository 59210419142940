import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { resetPassword } from './authSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

export default function ResetPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [newPassword, setNewPassword] = useState('')
    const [repeatNewPassword, setRepeatNewPassword] = useState('')
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const { token } = useParams();

    const handleSubmit = (event) => {
        event.preventDefault();

        if (passwordsMatch) {
            const resetPasswordData = {
                data:{
                    newPassword,
                    repeatNewPassword,
                    token
                },
                navigate,
            }
            dispatch(resetPassword(resetPasswordData));
        }
    };

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
        setPasswordsMatch(_.isEqual(e.target.value, repeatNewPassword));
    };

    const handleRepeatPasswordChange = (e) => {
        setRepeatNewPassword(e.target.value);
        setPasswordsMatch(_.isEqual(newPassword, e.target.value));
    };

    return (
        <Container maxWidth="xs" sx={{ mt: 20, mb: 20 }}>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary' }}>
                    <VpnKeyIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    <FormattedMessage id='resetPassword' />
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        type='password'
                        margin='normal'
                        required
                        fullWidth
                        label={<FormattedMessage id='newPassword' />}
                        value={newPassword}
                        onChange={handlePasswordChange}
                    />
                    <TextField
                        type='password'
                        margin='normal'
                        required
                        fullWidth
                        label={<FormattedMessage id='repeatNewPassword' />}
                        value={repeatNewPassword}
                        onChange={handleRepeatPasswordChange}
                    />
                    {(!passwordsMatch && repeatNewPassword) && <Typography color='error'><FormattedMessage id="passwordsNoMatch" /></Typography>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        <FormattedMessage id='save' />
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}