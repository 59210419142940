import {
  getCompanyById,
  getAllCompanies,
  updateCompanyInfo,
  addCompanyCredits,
  removeCompanyCredits,
  deleteCompanyCall,
} from '../../utils/requests/apiCalls'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import {
  setCompanies,
  setSelectedCompany,
  fetchAllCompanies,
  fetchSelectedCompany,
  postCompanyCredits,
  setTotalCompanies,
  companiesSliceSelector,
  postCompanyInfo,
  setEditedCompany,
  editCompanies,
  removeCompany,
  deleteCompany,
} from './companiesSlice'
import { toast } from 'react-toastify';

function* fetchAllCompaniesSaga(action) {
  try {
    const { filterOptions } = yield select(companiesSliceSelector);

    const res = yield call(getAllCompanies, filterOptions)
    yield put(setCompanies(res.companies))
    yield put(setTotalCompanies(res.count))

  } catch (error) { }
}

function* fetchSelectedCompanySaga(action) {
  try {
    yield put(setSelectedCompany({}))

    const res = yield call(getCompanyById, action.payload)

    yield put(setSelectedCompany(res.user[0]))
  } catch (error) {
    console.log('error', error)
  }
}

function* putCompanyInfoSaga(action) {
  try {
    const res = yield call(updateCompanyInfo, action.payload)
    const { filterOptions } = yield select(companiesSliceSelector);

    if (res.statusCode === 200) {
      yield put(setSelectedCompany(res.data))
      yield fetchAllCompaniesSaga()
      toast.success('Company updated successfully')
    } else {
      toast.error(res.message)
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* putCompanyCreditsSaga(action) {
  try {
    let res;

    if (action.payload.operation === 'add') {
      res = yield call(addCompanyCredits, action.payload)
    } else {
      res = yield call(removeCompanyCredits, action.payload)
    }

    if (res.statusCode === 200) {
      toast.success('Credits updated successfully')
      console.log('hahahaha', res)
      yield put(editCompanies(res.user))
      yield put(setEditedCompany({ key: 'credits', value: res.user.credits }))
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* deleteCompanySaga(action) {
  try {
    const res = yield call(deleteCompanyCall, action.payload)

    if (res.statusCode === 200) {
      yield put(setSelectedCompany({}))
      yield put(removeCompany(action.payload))
      toast.success(res.message)
    }

  } catch (error) {
    console.log('error', error)
  }
}

export function* watchCompaniesSaga() {
  yield takeLatest(fetchAllCompanies.type, fetchAllCompaniesSaga)
  yield takeLatest(fetchSelectedCompany.type, fetchSelectedCompanySaga)
  yield takeLatest(postCompanyCredits.type, putCompanyCreditsSaga)
  yield takeLatest(postCompanyInfo.type, putCompanyInfoSaga)
  yield takeLatest(deleteCompany.type, deleteCompanySaga)
}
