import { useEffect, useRef } from 'react';

// Use this hook when you want to only run it if the dependency changes
// and never in the first render of the component
const useOnDependencyChange = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

export default useOnDependencyChange;