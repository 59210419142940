import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import authReducer from '../features/authComponent/authSlice';
import adminReducer from '../features/adminsComponent/adminSlice';
import companiesReducer from '../features/companiesComponent/companiesSlice';
import createCVReducer from '../features/createCVComponent/createCVSlice'
import cvsReducer from '../features/cvsComponent/CVsSlice'
import editCvReducer from '../features/editCvComponent/editCvSlice'
import packageReducer from '../features/packagesComponent/packagesSlice'
import globalReducer from './slice';
import rootSaga from './saga';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create the store middlewares array, to include saga middleware
const middleware = [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware];

const devMode = process.env.NODE_ENV === 'development';

if (devMode) {
  // If in devMode, add redux logging
  middleware.push(logger);
}

// Include all reducers to store
const reducer = combineReducers({
  globalState: globalReducer,
  authState: authReducer,
  adminsState: adminReducer,
  companiesState: companiesReducer,
  createCvState: createCVReducer,
  cvsState: cvsReducer,
  editCvState: editCvReducer,
  packagesState: packageReducer,
});

export const store = configureStore({
  reducer,
  devTools: devMode,
  middleware
});

// Start sagas
sagaMiddleware.run(rootSaga);