import CompaniesPage from './features/companiesComponent/CompaniesPage';
import Header from "./features/headerComponent/Header";
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import SignIn from "./features/authComponent/SignIn";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderOverlay from "./features/loadingComponent/LoaderOverlay";
import { useDispatch, useSelector } from "react-redux";
import { globalSliceSelector } from "./app/slice";
import { AuthGuard } from './routing/ProtectedComponent';
import { IntlProvider } from 'react-intl'
import messages from './translation/messages.json'
import CreateCV from './features/createCVComponent/CreateCV.js';
import CVsPage from './features/cvsComponent/CVsPage.js';
import EditCvPage from './features/editCvComponent/EditCvPage.js';
import AdminsPage from './features/adminsComponent/AdminsPage.js';
import generateTheme from './style/style.js';
import { useEffect } from 'react';
import { signIn } from './features/authComponent/authSlice.js';
import ResetPassword from './features/authComponent/ResetPassword.js';
import PackagesPage from './features/packagesComponent/PackagesPage.js';

function App() {
  const { isLoading, language } = useSelector(globalSliceSelector);
  const theme = generateTheme(language);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const userID = localStorage.getItem('userId')

    //Persist user in redux after refresh and navigate to the page he was before
    if (userID) {
      dispatch(signIn({ authType: 'token', navigate, navTo: location.pathname }));
    }
  }, [dispatch]);

  return (
    <IntlProvider messages={(messages)[language]} locale={language} defaultLocale="en">
        <ThemeProvider theme={theme}>
          {isLoading && <LoaderOverlay showOverlay={true} />}
          <Header />
          <Routes>
            <Route path='/sign-in' element={<SignIn />} />
            <Route path='/' element={<AuthGuard><CompaniesPage /></AuthGuard>} />
            <Route path='create-cv' element={<AuthGuard><CreateCV /></AuthGuard>} />
            <Route path='cvs' element={<AuthGuard><CVsPage /></AuthGuard>} />
            <Route path='edit-cv/:id' element={<AuthGuard><EditCvPage /></AuthGuard>} />
            <Route path='/admins' element={<AuthGuard><AdminsPage /></AuthGuard>} />
            <Route path='/packages' element={<AuthGuard><PackagesPage /></AuthGuard>} />
            <Route path='/reset-password/:token' element={<ResetPassword />} />
            {/* <Route path='/admin-users' element={<AuthGuard><AdminUsersPage /></AuthGuard>} /> */}
            {/* <Route path='/cvs' element={<AuthGuard><CVSPage /></AuthGuard>} /> */}
          </Routes>
          <ToastContainer />
        </ThemeProvider>
    </IntlProvider>

  );
}

export default App;