import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from '@mui/material';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { createCvSliceSelector, setPrimarySkillTextInput, setSecondarySkillTextInput, setLanguageTextInput, deletePrimarySkill, addPrimarySkill, addSecondarySkill, deleteSecondarySkill, addlanguage, deleteLanguage } from '../createCVSlice';

function Skills() {
    const intl = useIntl()
    const dispatch = useDispatch()
    const { error, formState, primarySkillTextInput, secondarySkillTextInput, languageTextInput } = useSelector(createCvSliceSelector)

    const handleSkillsChanges = (e) => {
        const key = e.target.name
        const value = e.target.value
        if (key === 'primarySkills') {
            dispatch(setPrimarySkillTextInput(value))
        }
        if (key === 'secondarySkills') {
            dispatch(setSecondarySkillTextInput(value))
        }
        if (key === 'languages') {
            dispatch(setLanguageTextInput(value))
        }
    }

    const addSkill = (type) => {
        if (type === 'primarySkills') {
            dispatch(addPrimarySkill())
        }
        if (type === 'secondarySkills') {
            dispatch(addSecondarySkill())
        }
        if (type === 'languages') {
            dispatch(addlanguage())
        }
    }

    const deleteSkill = (index, type) => {
        if (type === 'primarySkills') {
            dispatch(deletePrimarySkill(index))
        }
        if (type === 'secondarySkills') {
            dispatch(deleteSecondarySkill(index))
        }
        if (type === 'languages') {
            dispatch(deleteLanguage(index))
        }
    }

    const createChips = (data, type) => {
        return (
            <Grid container spacing={1} alignItems='center'>
                {data?.map((skill, index) => (
                    <Grid margin={0.5} key={skill}>
                        <Chip
                            label={skill}
                            sx={{ bgcolor: 'primary.main', opacity: 0.9 }}
                            onDelete={() => deleteSkill(index, type)}
                        />
                    </Grid>
                ))}
                {/* {data.length > 10 && <Typography variant='h4'> ...</Typography>} */}
            </Grid>
        )
    }

    return (
        <>
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                <Paper
                    variant='outlined'
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 230 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={intl.formatMessage({ id: 'primarySkills' }) + '*'}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        name='primarySkills'
                        value={primarySkillTextInput}
                        onChange={handleSkillsChanges}
                        onKeyDown={(e) => e.key === 'Enter' && addSkill('primarySkills')}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => addSkill('primarySkills')} >
                        <AddIcon />
                    </IconButton>
                </Paper>
                <Box sx={{ ml: 10 }}>
                    {error.primarySkills && <Typography style={{ color: 'red' }} children={<FormattedMessage id='createCv.error.addPrimarySkill' />} />}
                    {!error.primarySkills && createChips(formState.primarySkills, 'primarySkills')}
                </Box>
            </Stack>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                <Paper
                    variant='outlined'
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 230 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={intl.formatMessage({ id: 'secondarySkills' })}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        name='secondarySkills'
                        value={secondarySkillTextInput}
                        onChange={handleSkillsChanges}
                        onKeyDown={(e) => e.key === 'Enter' && addSkill('secondarySkills')}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => addSkill('secondarySkills')}>
                        <AddIcon />
                    </IconButton>
                </Paper>
                <Box sx={{ ml: 10 }}>
                    {error.secondarySkills && <Typography style={{ color: 'red' }} children={<FormattedMessage id='createCv.error.addSecondarySkill' />} />}
                    {!error.secondarySkills && createChips(formState.secondarySkills, 'secondarySkills')}
                </Box>
            </Stack>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Stack direction={'row'} sx={{ alignItems: 'center' }}>
                <Paper
                    variant='outlined'
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', minWidth: 230 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={intl.formatMessage({ id: 'languages' }) + '*'}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        name='languages'
                        value={languageTextInput}
                        onChange={handleSkillsChanges}
                        onKeyDown={(e) => e.key === 'Enter' && addSkill('languages')}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => addSkill('languages')}>
                        <AddIcon />
                    </IconButton>
                </Paper>
                <Box sx={{ ml: 10 }}>
                    {error.languages && <Typography style={{ color: 'red' }} children={<FormattedMessage id='createCv.error.addLanguage' />} />}
                    {!error.languages && createChips(formState.languages, 'languages')}
                </Box>
            </Stack>
        </>
    )
}

export default Skills