export const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    location: {
        country: '',
        city: '',
        areaOfApplication: '',
    },
    phoneNumber: '',
    workModel: '',
    yearsOfExperience: 0,
    jobTitle: '',
    salaryRatePerHour: 0,
    level: '',
    startInWeeks: 0,
    teaser: {
        description: '',
        companySize: '',
    },
    willToTravel: false,
    readyToRelocate: false,
    proExperience: [],
    primarySkills: [],
    secondarySkills: [],
    languages: [],
    contractType: '',
    price: 0,
    lastContact: null
}

export const errorInitialState = {
    firstName: false,
    lastName: false,
    email: false,
    locationCountry: false,
    locationCity: false,
    locationAreaOfApplication: false,
    phoneNumber: false,
    workModel: false,
    yearsOfExperience: false,
    jobTitle: false,
    salaryRatePerHour: false,
    level: false,
    startInWeeks: false,
    teaserDescription: false,
    teaserCompanySize: false,
    willToTravel: false,
    readyToRelocate: false,
    primarySkills: false,
    secondarySkills: false,
    languages: false,
    contractType: false,
    price: false,
}

export const initialStateNewProExperience = {
    companyName: '',
    start: null,
    end: null,
    location: '',
    workType: '',
    title: '',
    companyType: '',
    description: '',
}

export const errorProExpInitialState = {
    companyName: false,
    start: false,
    end: false,
    location: false,
    title: false,
    companyType: false,
}

export const errorEditJobFormInitialState = {
    companyName: false,
    start: false,
    end: false,
    location: false,
    workType: false,
    title: false,
    companyType: false,
}