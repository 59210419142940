import { baseURL } from "../constants"

const baseURI = `${baseURL}/api`

// const userID = localStorage.getItem('userId')

export const SIGN_IN = `${baseURI}/admins/auth`
export const TOKEN_AUTH = `${baseURI}/admins/auth-token`
export const RESET_PASSWORD = `${baseURI}/admins/reset-admin-password`
export const FORGOT_PASSWORD = `${baseURI}/admins/forgot-password`
export const DELETE_ADMIN = (id) =>  `${baseURI}/admins/${id}`
export const DELETE_COMPANY = (id) => `${baseURI}/users/${id}`

export const GET_COMPANY = (id) => `${baseURI}/users/${id}`;
// export const GET_ALL_CVS = `${baseURI}/cv/`;
export const GET_ALL_CVS = `${baseURI}/cv/get-cvs-admin`
export const GET_COMPANIES_NAMES = `${baseURI}/users/names`;
export const GET_ALL_COMPANIES = `${baseURI}/users/all`;
export const GET_ALL_ADMINS = `${baseURI}/admins/all`;
export const GET_ADMIN_BY_ID = (id) => `${baseURI}/admins/${id}`;

export const CREATE_ADMIN = `${baseURI}/admins/register`;
export const UPDATE_ADMIN = `${baseURI}/admins/update`;
export const RESET_ADMIN_PASS = `${baseURI}/admins/reset-super-admin-password`;

export const ADD_CREDITS = (id) => `${baseURI}/credits/${id}/add`;
export const REMOVE_CREDITS = (id) => `${baseURI}/credits/${id}/reduce`;

export const GET_COMPANIES_BY_ID = `${baseURI}/users/details`;
export const EDIT_COMPANY_INFO = (id) => `${baseURI}/users/${id}/changeInfo`
export const EDIT_COMPANY_CREDITS = `${baseURI}/users/credits`;
export const CREATE_CV = `${baseURI}/cv/create`
export const UPDATE_CV = (cvId) => `${baseURI}/cv/${cvId}/update-cv`
export const DELETE_CV = `${baseURI}/cv/delete`
export const GET_SORTED_CV_FOR_ADMIN = (cvId) => `${baseURI}/cv/admin-sorted/${cvId}`
export const GET_CVS_BY_IDS = `${baseURI}/cv//ids-admin`
export const EXTRACT_PROFILE_FROM_PDF = `${baseURI}/linkedin/extractProfileFromPdfFile`


export const GET_ALL_PACKAGES = `${baseURI}/packages`
export const GET_PACKAGE = (id) => `${baseURI}/packages/${id}`
export const CREATE_PACKAGE = `${baseURI}/packages/create-package`
export const UPDATE_PACKAGE = `${baseURI}/packages/update-package`
export const DELETE_PACKAGE = (id) => `${baseURI}/packages/${id}`