import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, TextField, Typography, Stack, FormControlLabel, Switch } from '@mui/material'
import Modal from '@mui/material/Modal'
import { FormattedMessage, useIntl } from 'react-intl'
import { companiesSliceSelector, setEditedCompany, postCompanyInfo, deleteCompany } from './companiesSlice'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { globalSliceSelector } from '../../app/slice'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ConfirmationDialog from '../dialogs/ConfirmationDialog'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
}

export default function EditCompanyModal({ open, close }) {
    const dispatch = useDispatch()
    const { language } = useSelector(globalSliceSelector)
    const intl = useIntl();
    const { selectedCompany } = useSelector(companiesSliceSelector)
    const [emailError, setEmailError] = useState('');

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openCheckCreditsModal, setOpenCheckCreditsModal] = useState(false);
    const [dateError, setDateError] = useState('');

    const validateEmail = (email) => {
        if (email.length === 0 || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            setEmailError(''); // Clear any previous error
        } else {
            setEmailError('Invalid email format');
        }
    };

    const closeModal = () => {
        //Pass false to parent state: openModal
        setDateError('');
        close(false);
    }

    const handleChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;

        if (key === "phoneNumber") {
            // Sanitize the phone input to contain only numbers
            const numericPhone = value.replace(/[^0-9+\-/]/g, '');
            dispatch(setEditedCompany({ key, value: numericPhone }))
        } else if (key === "email") {
            dispatch(setEditedCompany({ key, value }))
            validateEmail(value);
        } else if (key === "verified") {
            const checkedValue = e.target.checked;
            dispatch(setEditedCompany({ key, value: checkedValue }))
        } else if (key === "customerNumber") {
            // Sanitize the customerNumber input to contain only numbers/letters
            const alphanumeric = value.replace(/[^A-Za-z0-9]/g, '');
            dispatch(setEditedCompany({ key, value: alphanumeric.toUpperCase() }))
        } else {
            dispatch(setEditedCompany({ key, value }))
        }
    }

    const handleLicenseChange = (date, name) => {
        if (moment.isMoment(date) && date.isValid()) {

            if (!selectedCompany.licenseStart && !selectedCompany.licenseEnd) {
                setDateError('');
            } else if (name === 'licenseEnd' && selectedCompany.licenseStart && date.isBefore(selectedCompany.licenseStart)) {
                setDateError(<FormattedMessage id='createCv.error.startDateBiggerThanEndDate' />);
            } else if (name === 'licenseStart' && selectedCompany.licenseEnd && date.isAfter(selectedCompany.licenseEnd)) {
                setDateError(<FormattedMessage id='createCv.error.startDateBiggerThanEndDate' />);
            } else if (name === 'licenseStart' && date.isAfter(new Date())) {
                setDateError(<FormattedMessage id='createCv.error.startDateInFuture' />);
            } else {
                setDateError('');
            }

            dispatch(setEditedCompany({ key: name, value: date.toDate() }));
        }
    };

    const save = (e) => {
        e.preventDefault();

        // if one Date exists
        if (selectedCompany.licenseStart || selectedCompany.licenseEnd) {
            const startOrEndIsMissing =
                (selectedCompany.licenseStart && !selectedCompany.licenseEnd)
                || (!selectedCompany.licenseStart && selectedCompany.licenseEnd)

            if (startOrEndIsMissing) {
                setDateError(<FormattedMessage id='fillBothDates' />);
                return;
            }
        }
        if (emailError || dateError) {
            // Don't submit the form if there is an email format error
            return;
        } else {
            dispatch(postCompanyInfo(selectedCompany))
        }
    }

    const confirmCompanyDelete = () => {
        if (selectedCompany?.credits > 0) {
            setOpenCheckCreditsModal(true);
        } else {
            dispatch(deleteCompany(selectedCompany?._id))
            setOpenDeleteModal(false);
            closeModal()
        }
    }

    const confirmCheckCredits = () => {
        dispatch(deleteCompany(selectedCompany?._id))
        setOpenCheckCreditsModal(false);
        setOpenDeleteModal(false);
        closeModal()
    }

    const cancelCompanyDelete = () => {
        setOpenCheckCreditsModal(false);
        setOpenDeleteModal(false);
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component='h1' variant='h5'>
                            <FormattedMessage id='changePersonalInfo' />
                        </Typography>
                        <Stack component="form" onSubmit={save} spacing={2} sx={{ mt: 4 }}>
                            <Stack direction={'row'} justifyContent={'space-between'}>
                                <FormControlLabel
                                    sx={{ ml: 0 }}
                                    control={<Switch color="primary" />}
                                    label={intl.formatMessage({ id: "verified" })}
                                    labelPlacement="start"
                                    name='verified'
                                    checked={selectedCompany?.verified}
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction={'row'} spacing={1}>
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    value={selectedCompany?.firstName || ''}
                                    label={<FormattedMessage id="firstName" />}
                                    name='firstName'
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    value={selectedCompany?.lastName || ''}
                                    label={<FormattedMessage id="lastName" />}
                                    name='lastName'
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    value={selectedCompany?.position || ''}
                                    label='Position'
                                    name='position'
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction={'row'} spacing={1}>
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    value={selectedCompany?.companyName || ''}
                                    label={<FormattedMessage id="companyName" />}
                                    name='companyName'
                                    onChange={handleChange}
                                />
                                <TextField
                                    error={!!emailError}
                                    helperText={emailError}
                                    margin='normal'
                                    required
                                    fullWidth
                                    value={selectedCompany?.email || ''}
                                    label='Email'
                                    name='email'
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction={'row'} spacing={1}>
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    value={selectedCompany?.address || ''}
                                    label={<FormattedMessage id="address" />}
                                    name='address'
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    value={selectedCompany?.phoneNumber || ''}
                                    label={<FormattedMessage id="phone" />}
                                    name='phoneNumber'
                                    onChange={handleChange}
                                />
                            </Stack>
                            <Stack direction={'row'} spacing={1}>
                                <TextField
                                    margin='normal'
                                    value={selectedCompany?.customerNumber || ''}
                                    label={<FormattedMessage id="customerNum" />}
                                    name='customerNumber'
                                    onChange={handleChange}
                                />
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                                    <DatePicker
                                        maxDate={selectedCompany?.licenseEnd && moment(selectedCompany?.licenseEnd).subtract(1, 'days')}
                                        label={<FormattedMessage id='licenseStart' />}
                                        name='licenseStart'
                                        views={['month', 'year', 'day']}
                                        value={selectedCompany?.licenseStart && moment(selectedCompany?.licenseStart)}
                                        onChange={(date) => handleLicenseChange(date, 'licenseStart')}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                                    <DatePicker
                                        minDate={selectedCompany?.licenseStart && moment(selectedCompany?.licenseStart).add(1, 'days')}
                                        label={<FormattedMessage id='licenseEnd' />}
                                        name='licenseEnd'
                                        views={['month', 'year', 'day']}
                                        value={selectedCompany?.licenseEnd && moment(selectedCompany?.licenseEnd)}
                                        onChange={(date) => handleLicenseChange(date, 'licenseEnd')}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                                <Link href="#" onClick={() => setOpenDeleteModal(true)} variant="body2" color='secondary.dark'>
                                    <FormattedMessage id="deleteUserBtn" />
                                </Link>
                            <Stack direction={'row'} justifyContent={'space-between'} spacing={4}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    sx={{ bgcolor: 'primary.dark' }}
                                    onClick={closeModal}
                                >
                                    <FormattedMessage id='close' />
                                </Button>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                >
                                    <FormattedMessage id='save' />
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
            {/* Confirmation dialog to delete user */}
            <ConfirmationDialog
                open={openDeleteModal}
                agreeCallBack={confirmCompanyDelete}
                disagreeCallback={cancelCompanyDelete}
                title={"delete.dialog.user.title"}
                message={"delete.dialog.user.message"}
                messageValue={selectedCompany?.companyName}
            />
            {/* Confirmation dialog to delete user even if he has credits in his account */}
            <ConfirmationDialog
                open={openCheckCreditsModal}
                agreeCallBack={confirmCheckCredits}
                disagreeCallback={cancelCompanyDelete}
                title={"delete.dialog.user.title"}
                message={"delete.dialog.credits.message"}
                messageValue={selectedCompany?.credits}
            />
        </>
    )
}
