import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { editCvSliceSelector, setEditCvForm } from '../editCvSlice';

function GeneralInformation() {
    const dispatch = useDispatch()
    const { editCvForm, editCvFormError } = useSelector(editCvSliceSelector)

    const handleCvInputs = (e) => {
        const key = e.target.name
        const value = e.target.value
        if (key === 'areaOfApplication') {
            dispatch(setEditCvForm({ ...editCvForm, location: { ...editCvForm.location, [key]: value, }, }))
        } else if (key === 'description' || key === 'companySize') {
            dispatch(setEditCvForm({ ...editCvForm, teaser: { ...editCvForm.teaser, [key]: value, }, }))
        } else {
            dispatch(setEditCvForm({ ...editCvForm, [key]: value }))
        }
    }

    return (
        <>
            <div>
                <FormControl error={editCvFormError.contractType} sx={{ m: 1, width: 195 }} required>
                    <InputLabel id='demo-simple-select-autowidth-label'><FormattedMessage id='contractType' /></InputLabel>
                    <Select
                        labelId='demo-simple-select-autowidth-label'
                        id='demo-simple-select-autowidth'
                        autoWidth
                        label={<FormattedMessage id='contractType' />}
                        name='contractType'
                        value={editCvForm.contractType}
                        onChange={handleCvInputs}
                    >
                        <MenuItem value={'employment'}><FormattedMessage id='contractType.employment' /></MenuItem>

                    </Select>
                </FormControl>
                <FormControl error={editCvFormError.workModel} sx={{ m: 1, width: 195 }} required>
                    <InputLabel id='demo-simple-select-autowidth-label'>
                        <FormattedMessage id='workModel' />
                    </InputLabel>
                    <Select
                        labelId='demo-simple-select-autowidth-label'
                        id='demo-simple-select-autowidth'
                        autoWidth
                        label={<FormattedMessage id='workModel' />}
                        name='workModel'
                        value={editCvForm.workModel}
                        onChange={handleCvInputs}
                    >
                        <MenuItem value={'remote'}>Remote</MenuItem>
                        <MenuItem value={'hybrid'}>Hybrid</MenuItem>
                        <MenuItem value={'on-site'}>{<FormattedMessage id='on-site' />}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl error={editCvFormError.teaserCompanySize} sx={{ m: 1, width: 195 }} required>
                    <InputLabel id='demo-simple-select-autowidth-label'><FormattedMessage id='companySize' /></InputLabel>
                    <Select
                        labelId='demo-simple-select-autowidth-label'
                        id='demo-simple-select-autowidth'
                        autoWidth
                        label={<FormattedMessage id='companySize' />}
                        name='companySize'
                        value={editCvForm.teaser.companySize}
                        onChange={handleCvInputs}
                    >
                        <MenuItem value={'flexible'}><FormattedMessage id='companySize.flexible' /></MenuItem>
                        <MenuItem value={'startup'}>Start-Up</MenuItem>
                        <MenuItem value={'small-mid'}><FormattedMessage id='companySize.smallMid' /></MenuItem>
                        <MenuItem value={'group'}><FormattedMessage id='companySize.group' /></MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id='outlined-basic'
                    error={editCvFormError.locationAreaOfApplication}
                    value={editCvForm.location.areaOfApplication}
                    onChange={handleCvInputs}
                    name='areaOfApplication'
                    label={<FormattedMessage id='areaOfApplication' />}
                    variant='outlined'
                />
            </div>
            <Stack direction={'row'}>
                <TextField
                    id='outlined-basic'
                    error={editCvFormError.salaryRatePerHour}
                    label={<FormattedMessage id='salaryPerHour' />}
                    variant='outlined'
                    name='salaryRatePerHour'
                    value={editCvForm.salaryRatePerHour}
                    onChange={handleCvInputs}
                />
                <TextField
                    id='outlined-basic'
                    error={editCvFormError.startInWeeks}
                    label={<FormattedMessage id='startInWeeks' />}
                    variant='outlined'
                    name='startInWeeks'
                    value={editCvForm.startInWeeks}
                    onChange={handleCvInputs}
                />
                <Stack direction={'row'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name='willToTravel'
                                checked={editCvForm.willToTravel}
                                onChange={() => {
                                    dispatch(setEditCvForm({ ...editCvForm, willToTravel: !editCvForm.willToTravel }))
                                }}
                            />
                        }
                        label={<FormattedMessage id='willToTravel' />}
                        sx={{ ml: 1.5 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name='readyToRelocate'
                                checked={editCvForm.readyToRelocate}
                                onChange={() => {
                                    dispatch(setEditCvForm({ ...editCvForm, readyToRelocate: !editCvForm.readyToRelocate }))
                                }}
                            />
                        }
                        label={<FormattedMessage id='readyToRelocate' />}
                        sx={{ ml: 1.5 }}
                    />
                </Stack>
            </Stack>
            <div>
                <TextField
                    required
                    id='outlined-basic'
                    error={editCvFormError.jobTitle}
                    label={<FormattedMessage id='jobTitle' />}
                    variant='outlined'
                    name='jobTitle'
                    value={editCvForm.jobTitle}
                    onChange={handleCvInputs}
                />
                <FormControl error={editCvFormError.level} sx={{ m: 1, width: 195 }} required>
                    <InputLabel id='demo-simple-select-autowidth-label'>Level</InputLabel>
                    <Select
                        labelId='demo-simple-select-autowidth-label'
                        id='demo-simple-select-autowidth'
                        autoWidth
                        label='Level'
                        name='level'
                        value={editCvForm.level}
                        onChange={handleCvInputs}
                    >
                        <MenuItem value={'junior'}>Junior</MenuItem>
                        <MenuItem value={'mid'}>Mid</MenuItem>
                        <MenuItem value={'senior'}>Senior</MenuItem>
                        <MenuItem value={'lead'}>Lead</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id='outlined-basic'
                    error={editCvFormError.yearsOfExperience}
                    label={<FormattedMessage id='yearsOfExperience' />}
                    variant='outlined'
                    value={editCvForm.yearsOfExperience}
                    onChange={handleCvInputs}
                    name='yearsOfExperience'
                />
            </div>
            <Box>
                <TextField
                    fullWidth
                    id='outlined-basic'
                    error={editCvFormError.teaserDescription}
                    label={<FormattedMessage id='description' />}
                    name='description'
                    variant='outlined'
                    multiline
                    minRows={10}
                    maxRows={24}
                    value={editCvForm.teaser.description}
                    onChange={handleCvInputs}
                    placeholder='Add a full Description'
                />
            </Box>
        </>
    )
}

export default GeneralInformation