import { createSlice } from '@reduxjs/toolkit'
import { errorEditJobFormInitialState, errorInitialState, errorProExpInitialState, initialState, initialStateNewProExperience } from './createCvInitialState'

const CREATE_CV_SLICE = 'CREATE_CV_SLICE'

export const createCVSlice = createSlice({
  name: CREATE_CV_SLICE,
  initialState: {
    formState: initialState,
    error: errorInitialState,
    errorProExp: errorProExpInitialState,
    primarySkillTextInput: '',
    secondarySkillTextInput: '',
    languageTextInput: '',
    proExperienceForm: initialStateNewProExperience,
    editableRowIndex: null,
    editJobForm: initialStateNewProExperience,
    errorEditJobForm: errorEditJobFormInitialState,
    showViewCreateCvModal: false,
  },
  reducers: {
    setFormState: (state, action) => {
      state.formState = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setPrimarySkillTextInput: (state, action) => {
      state.primarySkillTextInput = action.payload
    },
    setSecondarySkillTextInput: (state, action) => {
      state.secondarySkillTextInput = action.payload
    },
    setLanguageTextInput: (state, action) => {
      state.languageTextInput = action.payload
    },
    setProExperienceForm: (state, action) => {
      state.proExperienceForm = action.payload
    },
    setErrorProExp: (state, action) => {
      state.errorProExp = action.payload
    },
    setEditableRowIndex: (state, action) => {
      state.editableRowIndex = action.payload
    },
    setEditJobForm: (state, action) => {
      state.editJobForm = action.payload
    },
    setErrorEditJobForm: (state, action) => {
      state.errorEditJobForm = action.payload
    },
    setShowViewCreateCvModal: (state, action) => {
      state.showViewCreateCvModal = action.payload
    },
    addNewProExp: () => { },
    deleteProExp: () => { },
    addPrimarySkill: () => { },
    deletePrimarySkill: () => { },
    addSecondarySkill: () => { },
    deleteSecondarySkill: () => { },
    addlanguage: () => { },
    deleteLanguage: () => { },
    saveEditedJob: () => { },
    validateCV: () => { },
    createCV: () => { },
    resetForm: () => { },
  },
})

export const {
  addNewProExp,
  deleteProExp,
  validateCV,
  createCV,
  setFormState,
  setPrimarySkillTextInput,
  setSecondarySkillTextInput,
  setLanguageTextInput,
  setProExperienceForm,
  addPrimarySkill,
  deletePrimarySkill,
  addSecondarySkill,
  deleteSecondarySkill,
  addlanguage,
  deleteLanguage,
  saveEditedJob,
  setError,
  setErrorProExp,
  setEditableRowIndex,
  setEditJobForm,
  setErrorEditJobForm,
  setShowViewCreateCvModal,
  resetForm,
} = createCVSlice.actions
export const createCvSliceSelector = (state) => state.createCvState

export default createCVSlice.reducer
