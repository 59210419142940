import { createSlice } from "@reduxjs/toolkit";
import { errorEditJobFormInitialState, errorInitialState, errorProExpInitialState, initialState, initialStateNewProExperience } from "../createCVComponent/createCvInitialState";

const editCvSlice = createSlice({
    name: 'EDIT_CV_SLICE',
    initialState: {
        editCvForm: initialState,
        editCvFormError: errorInitialState,
        lastSavedStateEditCvForm: initialState,
        primarySkillTextInput: '',
        secondarySkillTextInput: '',
        languageTextInput: '',
        proExperienceForm: initialStateNewProExperience,
        errorProExp: errorProExpInitialState,
        editableRowIndex: null,
        editJobForm: initialStateNewProExperience,
        errorEditJobForm: errorEditJobFormInitialState,
        showViewEditCvModal: false,
    },
    reducers: {
        setEditCvForm: (state, action) => {
            state.editCvForm = action.payload
        },
        setEditCvFormError: (state, action) => {
            state.editCvFormError = action.payload
        },
        setLastSavedStateEditCvForm: (state, action) => {
            state.lastSavedStateEditCvForm = action.payload
        },
        setPrimarySkillTextInput: (state, action) => {
            state.primarySkillTextInput = action.payload
        },
        setSecondarySkillTextInput: (state, action) => {
            state.secondarySkillTextInput = action.payload
        },
        setLanguageTextInput: (state, action) => {
            state.languageTextInput = action.payload
        },
        setProExperienceForm: (state, action) => {
            state.proExperienceForm = action.payload
        },
        setErrorProExp: (state, action) => {
            state.errorProExp = action.payload
        },
        setEditableRowIndex: (state, action) => {
            state.editableRowIndex = action.payload
        },
        setEditJobForm: (state, action) => {
            state.editJobForm = action.payload
        },
        setShowViewEditCvModal: (state, action) => {
            state.showViewEditCvModal = action.payload
        },
        setErrorEditJobForm: (state, action) => {
            state.errorEditJobForm = action.payload
        },
        findCV: () => { },
        fetchCV: () => { },
        addNewProExp: () => { },
        deleteProExpEditCv: () => { },
        saveEditedJob: () => { },
        addPrimarySkill: () => { },
        deletePrimarySkill: () => { },
        addSecondarySkill: () => { },
        deleteSecondarySkill: () => { },
        addlanguage: () => { },
        deleteLanguage: () => { },
        validateCv: () => { },
        updateCv: () => { },
        resetForm: () => { },
        deleteCV: () => { },
    }
})

export const {
    findCV,
    fetchCV,
    updateCv,
    validateCv,
    addNewProExp,
    deleteProExpEditCv,
    saveEditedJob,
    setEditCvForm,
    setEditCvFormError,
    setLastSavedStateEditCvForm,
    setPrimarySkillTextInput,
    addPrimarySkill,
    deletePrimarySkill,
    addSecondarySkill,
    deleteSecondarySkill,
    addlanguage,
    deleteLanguage,
    setSecondarySkillTextInput,
    setLanguageTextInput,
    setProExperienceForm,
    setErrorProExp,
    setEditableRowIndex,
    setEditJobForm,
    setShowViewEditCvModal,
    setErrorEditJobForm,
    resetForm,
    deleteCV,
} = editCvSlice.actions
export const editCvSliceSelector = (state) => state.editCvState

export default editCvSlice.reducer