import { createSlice } from "@reduxjs/toolkit";

const CVS_SLICE = 'CVS_SLICE'
const cvsSlice = createSlice({
    name: CVS_SLICE,
    initialState: {
        cvs: [],
        totalCvs: 0,
        filterOptions: {
            page: 0,
            pageSize: 10,
            sortField: 'lastName',
        }
    },
    reducers: {
        setCvs: (state, action) => {
            state.cvs = action.payload
        },
        setFilterOptions: (state, action) => {
            const { key, value } = action.payload;
            if (state.filterOptions.hasOwnProperty(key)) {
                state.filterOptions[key] = value;
            }
        },
        removeCV: (state, action) => {
            state.cvs = state.cvs.filter(cv =>
                cv._id !== action.payload
            )
            state.totalCvs -= 1;
        },
        setTotalCvs: (state, action) => {
            state.totalCvs = action.payload
        },
        getCvs: () => { },
    }
})

export const { setCvs, getCvs, setSelectedCV, setShowViewCVModal, setFilterOptions, setTotalCvs, removeCV} = cvsSlice.actions
export const cvsSliceSelector = (state) => state.cvsState

export default cvsSlice.reducer