import React, { useState } from 'react';
import { Button, Stack, TextField, Container, Typography, Modal } from '@mui/material';
import { useDispatch } from 'react-redux';
import { resetAdminPass } from './adminSlice';
import _ from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
}

const CreateAdminModal = ({ open, close, adminId }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordsMatch(_.isEqual(e.target.value, repeatPassword));
    };

    const handleRepeatPasswordChange = (e) => {
        setRepeatPassword(e.target.value);
        setPasswordsMatch(_.isEqual(password, e.target.value));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (passwordsMatch) {
            dispatch(resetAdminPass({ password, repeatPassword, adminId }))
            close(false);
        }
    };

    const closeModal = () => {
        setRepeatPassword('')
        setPassword('')
        //Pass false to parent state: openModal
        close(false);
    }

    return (
        <Modal
            open={open}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Container component="main" maxWidth="xs" sx={style}>
                <Typography variant="h5" align="center" mb={3}>
                    <FormattedMessage id="changePassword" />
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <TextField
                            fullWidth
                            label={intl.formatMessage({ id: "newPassword" })}
                            name="password"
                            value={password}
                            onChange={handlePasswordChange}
                            type='password'
                            required
                            m={2}
                        />
                        <TextField
                            fullWidth
                            label={intl.formatMessage({ id: "repeatNewPassword" })}
                            name="repeatPassword"
                            type='password'
                            value={repeatPassword}
                            onChange={handleRepeatPasswordChange}
                            required
                            p={2}
                        />
                        {(!passwordsMatch && repeatPassword) && <Typography color='error'><FormattedMessage id="passwordsNoMatch" /></Typography>}
                        <Stack direction={'row'} spacing={3}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                            <FormattedMessage id="save" />
                            </Button>
                            <Button variant="contained" color="success" fullWidth onClick={closeModal}>
                            <FormattedMessage id="cancel" />

                            </Button>
                        </Stack>

                    </Stack>
                </form>
            </Container>
        </Modal>
    );
};

export default CreateAdminModal;
