import React from 'react'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createCvSliceSelector, setFormState } from '../createCVSlice'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import { globalSliceSelector } from '../../../app/slice'

function Profilemanagement() {
  const dispatch = useDispatch()
  const { language } = useSelector(globalSliceSelector)
  const { formState, error } = useSelector(createCvSliceSelector)

  const handleCvInputs = (e) => {
    const key = e.target.name
    const value = e.target.value
    dispatch(setFormState({ ...formState, [key]: value }))
  }

  const handleDatePickersChange = (date, key) => {
    if (date._d instanceof Date && !isNaN(date._d)) {
      dispatch(setFormState({ ...formState, [key]: date }))
    }
  }

  return (
    <>

      <Stack direction='row' gap={5} sx={{ alignItems: 'center' }}>
        <TextField
          id='outlined-basic'
          error={error.price}
          label={<FormattedMessage id='pricePerCredits' />}
          variant='outlined'
          name='price'
          value={formState.price}
          onChange={handleCvInputs}
        />
      </Stack>
      <Stack direction='row' gap={5} sx={{ alignItems: 'center' }}>
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                disableFuture
                sx={{ width: 245 }}
                label={<FormattedMessage id='lastContact' />}
                name='lastContact'
                views={['month', 'year', 'day']}
                value={formState.lastContact && moment(formState.lastContact)}
                onChange={(date) => { handleDatePickersChange(date, 'lastContact') }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Stack>
    </>
  )
}

export default Profilemanagement