import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Modal from '@mui/material/Modal'
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { globalSliceSelector } from '../../app/slice'
import { createCV, setShowViewCreateCvModal } from '../createCVComponent/createCVSlice'
import { setShowViewEditCvModal, updateCv } from '../editCvComponent/editCvSlice'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    overflow: 'auto',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    height: '80%',
}

export default function ViewCVModal(props) {
    const { showCv, selectedCV, setShowModal, type } = props
    const intl = useIntl()
    const dispatch = useDispatch()
    const { language } = useSelector(globalSliceSelector);
    const navigate = useNavigate();

    const closeModal = () => {
        if (type === 'viewCv') {
            setShowModal(false)
        }
        if (type === 'createCv') {
            dispatch(setShowViewCreateCvModal(false))
        }
        if (type === 'editCv') {
            dispatch(setShowViewEditCvModal(false))
        }
    }

    const onSubmit = () => {
        const payload = { navigate }
        if (type === 'createCv') {
            dispatch(createCV(payload))
        }
        if (type === 'editCv') {
            dispatch(updateCv(payload))
        }
    }

    const renderLevelText = (value) => {
        if (!value) {
            return 'Level: '
        }
        const firstLetterCapital = value.charAt(0).toUpperCase() + value.slice(1)

        return `Level: ${firstLetterCapital}`
    }

    const renderWorkModelText = (value) => {
        if (value === 'on-site') {
            return intl.formatMessage({ id: 'on-site' })
        }
        return value ? value.charAt(0).toUpperCase() + value.slice(1) : ''
    }

    const renderContractTypeText = (value) => {
        if (value === 'employment') {
            return intl.formatMessage({ id: 'contractType.employment' })
        }
        return value || ''
    }

    const renderBooleanYesOrNoText = (value) => {
        if (typeof value !== "boolean") {
            return ''
        }

        let textToRender
        if (value === true) {
            textToRender = language === 'de' ? 'Ja' : 'Yes'
        }
        if (value === false) {
            textToRender = language === 'de' ? 'Nein' : 'No'
        }

        return textToRender
    }

    const renderDateFormatText = (date) => {
        if (date) {
            return language === 'de' ? moment(date).format('MM.yyyy') : moment(date).format('MM/yyyy')
        }
        return <FormattedMessage id='currentDate' />

    }

    const renderDateFormatBottomCVDetails = (date) => {
        if (date) {
            return language === 'de' ? moment(date).format('DD.MM.YYYY - hh:mm:ss') : moment(date).format('DD/MM/YYYY - hh:mm:ss')
        }
        return ''
    }

    const renderMoneyText = (money) => {
        let moneyFormat = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return money ? moneyFormat.format(money) : ''
    }

    const renderCompanySizeText = (value) => {
        if (!value) {
            return ''
        }
        if (value === 'flexible') {
            return <FormattedMessage id='companySize.flexible' />
        }
        if (value === 'startup') {
            return 'Start-Up'
        }
        if (value === 'small-mid') {
            return <FormattedMessage id='companySize.smallMid' />
        }
        if (value === 'group') {
            return <FormattedMessage id='companySize.group' />
        }
        return value
    }

    const renderProExperienceList = () => {
        if (!selectedCV.proExperience) return

        const deepClonedProExperience = JSON.parse(JSON.stringify(selectedCV?.proExperience))
        const sortProExperience = deepClonedProExperience.sort((a, b) => new Date(b.start) - new Date(a.start))
        return sortProExperience.map(exp => (
            <>
                <Stack direction='row' gap={10} my={4} >
                    <Box width={160}>
                        <Typography fontWeight='bold'>{renderDateFormatText(exp.start)} - {renderDateFormatText(exp.end)}</Typography>
                        <Typography color='gray'>{exp.location}</Typography>
                        <Typography color='gray'>{renderWorkModelText(exp.workType)}</Typography>
                    </Box>
                    <Box>
                        <Typography fontWeight='bold' >{exp.title}</Typography>
                        <Typography color='gray'>{exp.companyName}</Typography>
                        <Typography>{exp.companyType}</Typography>
                        <Typography color='gray'>{exp.description}</Typography>
                    </Box>
                </Stack>
            </>
        ))
    }

    const createChips = (data) => {
        return (
            <Grid container spacing={1} alignItems='center'>
                {data?.map((skill) => (
                    <Grid margin={0.5} key={skill}>
                        <Chip
                            label={skill}
                            sx={{ bgcolor: 'primary.main', opacity: 0.9 }}
                        />
                    </Grid>
                ))}
            </Grid>
        )
    }

    return (
        <div>
            <Modal
                open={showCv}
                onClose={closeModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Box>
                            <Typography component='h1' variant='h5' fontWeight='bold' >
                                {selectedCV?.firstName + ' ' + selectedCV?.lastName}
                            </Typography>
                            <Typography component='h1' variant='h6' >
                                {selectedCV?.jobTitle}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                            <Typography>
                                {selectedCV?.email}
                            </Typography>
                            <Typography>
                                {selectedCV?.phoneNumber}
                            </Typography>
                            <Typography sx={{ color: 'gray' }}>
                                {`${selectedCV?.location?.country}, ${selectedCV?.location?.city}`}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack mt={1} direction='column'>
                        <Typography>
                            {renderLevelText(selectedCV?.level)}
                        </Typography>
                        <Typography>
                            Work Model: {renderWorkModelText(selectedCV?.workModel)}
                        </Typography>
                        <Typography>
                            <FormattedMessage id='yearsOfExperience' />: {selectedCV?.yearsOfExperience}
                        </Typography>
                        <Typography>
                            <FormattedMessage id='salaryPerHour' />:  {renderMoneyText(selectedCV?.salaryRatePerHour)}
                        </Typography>
                        <Typography>
                            <FormattedMessage id='contractType' />: {renderContractTypeText(selectedCV?.contractType)}
                        </Typography>
                    </Stack>
                    <Stack my={5}>
                        <Typography>
                            <FormattedMessage id='areaOfApplication' />:  {selectedCV?.location?.areaOfApplication}
                        </Typography>
                        <Typography>
                            <FormattedMessage id='startInWeeks' />: {selectedCV?.startInWeeks}
                        </Typography>
                        <Typography>
                            <FormattedMessage id='companySize' />: {renderCompanySizeText(selectedCV?.teaser?.companySize)}
                        </Typography>
                        <Typography>
                            <FormattedMessage id='willToTravel' />: {renderBooleanYesOrNoText(selectedCV?.willToTravel)}
                        </Typography>
                        <Typography>
                            <FormattedMessage id='readyToRelocate' />: {renderBooleanYesOrNoText(selectedCV?.readyToRelocate)}
                        </Typography>
                        <Typography sx={{ mt: 5, color: 'gray' }}>
                            {selectedCV?.teaser?.description}
                        </Typography>
                    </Stack>
                    {selectedCV?.proExperience?.length !== 0 && (
                        <>
                            <Divider textAlign="left"><FormattedMessage id='professionalExperience' /></Divider>
                            {renderProExperienceList()}
                        </>
                    )}
                    <Box>
                        <Box my={3}>
                            <Divider textAlign="left"><FormattedMessage id='primarySkills' /></Divider>
                        </Box>
                        {createChips(selectedCV?.primarySkills)}
                    </Box>
                    {selectedCV?.secondarySkills?.length !== 0 && (
                        <Box>
                            <Box my={3}>
                                <Divider textAlign="left"><FormattedMessage id='secondarySkills' /></Divider>
                            </Box>
                            {createChips(selectedCV?.secondarySkills)}
                        </Box>
                    )}
                    <Box>
                        <Box my={3}>
                            <Divider textAlign="left"><FormattedMessage id='languages' /></Divider>
                        </Box>
                        {createChips(selectedCV?.languages)}
                    </Box>
                    <Stack sx={{ mt: 10, textAlign: 'end' }}>
                        <Typography sx={{ color: 'gray' }}>
                            <FormattedMessage id='CvPriceInCredits' />: {selectedCV?.price}
                        </Typography>
                        {selectedCV?.lastContact && (
                            <Typography sx={{ color: 'gray' }}>
                                <FormattedMessage id='lastContact' />: {renderDateFormatBottomCVDetails(selectedCV?.lastContact)}
                            </Typography>
                        )}
                        <Typography sx={{ color: 'gray' }}>
                            <FormattedMessage id='CvCreatedAt' />: {renderDateFormatBottomCVDetails(selectedCV?.createdAt)}
                        </Typography>
                        <Typography sx={{ color: 'gray' }}>
                            <FormattedMessage id='CvUpdatedAt' />: {renderDateFormatBottomCVDetails(selectedCV?.updatedAt)}
                        </Typography>
                    </Stack>
                    {type === 'createCv' && (
                        <>
                            <Box my={3} sx={{ position: 'sticky', bottom: 0, textAlign: 'center' }}>
                                <Box sx={{ mt: 5, display: 'flex', gap: 5, justifyContent: 'center' }}>
                                    <Button type="button" variant='contained' sx={{ bgcolor: 'primary.dark' }} onClick={closeModal}>
                                        <FormattedMessage id='close' />
                                    </Button>
                                    <Button type="button" variant='contained' onClick={onSubmit}>
                                        <FormattedMessage id='submitCv' />
                                    </Button>
                                </Box>
                            </Box>
                        </>
                    )}
                    {type === 'editCv' && (
                        <Box my={3} sx={{ position: 'sticky', bottom: 0, textAlign: 'center' }}>
                            <Box sx={{ mt: 5, display: 'flex', gap: 5, justifyContent: 'center' }}>
                                <Button type="button" variant='contained' sx={{ bgcolor: 'primary.dark' }} onClick={closeModal}>
                                    <FormattedMessage id='close' />
                                </Button>
                                <Button type="button" variant='contained' onClick={onSubmit}>
                                    <FormattedMessage id='save' />
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {type === 'viewCv' && (
                        <>
                            <Box sx={{
                                position: 'sticky',
                                bottom: 0,
                                textAlign: 'center'
                            }}>
                                <Button type="button" variant='contained' sx={{ bgcolor: 'primary.dark' }} onClick={closeModal}>
                                    <FormattedMessage id='close' />
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    )
}