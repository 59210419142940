export const EMAIL = 'mariakritou.dev@gmail.com'

const environmentUrls = {
    localhost: 'http://localhost:3001',
    development: '<YOUR_DEV_URL_HERE>',
    test: '<YOUR_TEST_URL_HERE>',
    production: 'https://api.ifyouwantme.de',
};

export const baseURL = environmentUrls[process.env.REACT_APP_ENV];
