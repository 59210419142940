import React, { useEffect, useState } from "react";
import { Stack, Box, Button, Typography } from '@mui/material/';
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from '@mui/x-data-grid';
import useOnDependencyChange from '../../customHooks/useOnDependencyChange'
import { adminsSliceSelector, fetchAllAdmins, fetchSelectedAdmin, setFilterOptions } from "./adminSlice";
import CreateAdminModal from "./CreateAdminModal";
import EditAdminModal from "./EditAdminModal";
import { FormattedMessage } from "react-intl";
import EditIcon from '@mui/icons-material/Edit';
import TablePaginationActions from "../paginationActionsComponent/PaginationActions";

export default function AdminsPage() {
    const { admins, totalAdmins, filterOptions } = useSelector(adminsSliceSelector);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (admins.length === 0) {
            dispatch(fetchAllAdmins());
        }
    }, [])

    useOnDependencyChange(() => {
        dispatch(fetchAllAdmins());
    }, [filterOptions]);

    const handleSortModelChange = (e) => {
        if (e.length !== 0) {
            const sort = e[0].sort === 'asc' ? 1 : -1;
            dispatch(setFilterOptions({ key: 'sortField', value: e[0].field }))
            dispatch(setFilterOptions({ key: 'sortOrder', value: sort }))
        }
    }

    const handlePaginationModelChange = (e) => {
        dispatch(setFilterOptions({ key: 'page', value: e.page }))
        dispatch(setFilterOptions({ key: 'pageSize', value: e.pageSize }))
    }

    const handleModalOpen = (id, modalType) => {
        dispatch(fetchSelectedAdmin(id));
        setOpenEditModal(true);
    };

    const closeModal = (data) => {
        setOpenAddModal(data)
        setOpenEditModal(data)
    };

    const columns = [
        { field: 'firstName', headerName: <FormattedMessage id="firstName" />, width: 150, filterable: false, headerClassName: 'datagrid-theme' },
        { field: 'lastName', headerName: <FormattedMessage id="lastName" />, width: 150, filterable: false, headerClassName: 'datagrid-theme' },
        { field: 'email', headerName: "Email", width: 250, filterable: false, headerClassName: 'datagrid-theme' },
        {
            headerName: <FormattedMessage id="action" />,
            width: 80,
            filterable: false,
            sortable: false,
            headerClassName: 'datagrid-theme',
            renderCell: (params) => (
                <Stack direction={'row'} display={'flex'} justifyContent={'space-evenly'}>
                    <Button
                        sx={{ m: 0.5, minWidth: 30 }}
                        variant="outlined"
                        onClick={() => handleModalOpen(params.row.id, 'edit')}
                    >
                        <EditIcon />
                    </Button>
                </Stack>
            ),
        },
    ];

    return (
        <Stack mt={3} display={'flex'} alignItems={'center'}>
            <Box width={'max-content'} >
                <Typography variant='h5' sx={{ mb: 3 }}><FormattedMessage id='adminUsers' /></Typography>
                <Stack mt={2} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'} >
                    <Box sx={{
                        height: [400, 400, 400, 430, 650], width: [600, 700, 'max-content', 'max-content', 'max-content'], '& .datagrid-theme': {
                            backgroundColor: 'primary.main',
                            color: "white",
                            '& .MuiDataGrid-sortIcon': {
                                color: 'white'
                            }
                        }
                    }}>
                        <DataGrid
                            rows={admins}
                            columns={columns}
                            sortingMode="server"
                            paginationMode='server'
                            onSortModelChange={(e) => handleSortModelChange(e)}
                            onPaginationModelChange={(e) => handlePaginationModelChange(e)}
                            onRowDoubleClick={(e) => handleModalOpen(e.id, 'edit')}
                            paginationModel={{ page: filterOptions.page, pageSize: filterOptions.pageSize }}
                            pageSizeOptions={[5, 10, 15]}
                            sortingOrder={['asc', 'desc']}
                            rowCount={totalAdmins}
                            disableRowSelectionOnClick
                            slotProps={{
                                pagination: {
                                    ActionsComponent: (props) => (
                                        <TablePaginationActions
                                            page={filterOptions.page}
                                            pageSize={filterOptions.pageSize}
                                            totalCount={totalAdmins}
                                            setFilterOptions={setFilterOptions}
                                            {...props}
                                        />
                                    ),
                                }
                            }}
                        />

                    </Box>
                </Stack>
                <Button onClick={() => setOpenAddModal(true)} variant="contained" mt={3} sx={{ float: 'right', mt: 2 }}><FormattedMessage id="createAdmin" /></Button>
            </Box>
            <CreateAdminModal open={openAddModal} close={closeModal} />
            <EditAdminModal open={openEditModal} close={closeModal} />
        </Stack>
    )
}