import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { useDispatch, useSelector } from 'react-redux'
import { editCvSliceSelector, setEditCvForm } from '../editCvSlice'
import { FormattedMessage } from 'react-intl'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import { globalSliceSelector } from '../../../app/slice'

function ProfileManagement() {
  const dispatch = useDispatch()
  const { language } = useSelector(globalSliceSelector)
  const { editCvForm, editCvFormError } = useSelector(editCvSliceSelector)

  const handleCvInputs = (e) => {
    const key = e.target.name
    const value = e.target.value
    dispatch(setEditCvForm({ ...editCvForm, [key]: value }))
  }

  const handleDatePickersChange = (date, key) => {
    if (date._d instanceof Date && !isNaN(date._d)) {
      dispatch(setEditCvForm({ ...editCvForm, [key]: date }))
    }
  }

  return (
    <>
      <Stack direction='row' gap={5} sx={{ alignItems: 'end' }}>
        <Box>
          <TextField
            id='outlined-basic'
            error={editCvFormError.price}
            label={<FormattedMessage id='pricePerCredits' />}
            variant='outlined'
            name='price'
            value={editCvForm.price}
            onChange={handleCvInputs}
          />
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                disableFuture
                sx={{ width: 230 }}
                label={<FormattedMessage id='lastContact' />}
                name='lastContact'
                views={['month', 'year', 'day']}
                value={editCvForm.lastContact && moment(editCvForm.lastContact)}
                onChange={(date) => { handleDatePickersChange(date, 'lastContact') }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Stack>
    </>
  )
}

export default ProfileManagement