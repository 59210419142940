import { call, put, select, takeLatest } from "redux-saga/effects";
import { cvsSliceSelector, getCvs, setCvs, setTotalCvs } from "./CVsSlice";
import { getAllCVs } from "../../utils/requests/apiCalls";

function* getCvsSaga() {
    try {
        const { filterOptions } = yield select(cvsSliceSelector);
        const res = yield call(getAllCVs, filterOptions)
        yield put(setCvs(res.cvs))
        yield put(setTotalCvs(res.count))

    } catch (error) { }
}

export function* watchCvsSaga() {
    yield takeLatest(getCvs.type, getCvsSaga)
}