import { createSlice } from '@reduxjs/toolkit'

const AUTH_SLICE = 'AUTH_SLICE'

const initialState = {
  authenticated: false,
  firstName: '',
  lastName: '',
};

export const authSlice = createSlice({
  name: AUTH_SLICE,
  initialState,
  reducers: {
    setSignInUser: (state, action) => {
      state.authenticated = true
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
    },
    setSignOutUser: (state) => {
      state.authenticated = false
      state.firstName = ''
      state.lastName = ''
      localStorage.removeItem('userId')
    },
    signIn: () => {},
    forgotPassword: () => {},
    resetPassword: () => {}
  }
})

export const {
  signIn,
  setSignInUser,
  setSignOutUser,
  forgotPassword,
  resetPassword
} = authSlice.actions;

export const authSliceSelector = (state) => state.authState

export default authSlice.reducer
