import React from 'react'
import TextField from '@mui/material/TextField'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { editCvSliceSelector, setEditCvForm } from '../editCvSlice';

function PersonalInformation() {
    const dispatch = useDispatch()
    const { editCvForm, editCvFormError } = useSelector(editCvSliceSelector)

    const handleCvInputs = (e) => {
        const key = e.target.name
        const value = e.target.value
        if (key === 'country' || key === 'city') {
            dispatch(setEditCvForm({ ...editCvForm, location: { ...editCvForm.location, [key]: value, } }))
        } else if (key === 'phoneNumber') {
            // allow only numbers and these characters / , ()
            dispatch(setEditCvForm({ ...editCvForm, [key]: value.replace(/[^0-9/()+-]/g, '') }))
        } else {
            dispatch(setEditCvForm({ ...editCvForm, [key]: value }))
        }
    }

    return (
        <>
            <div>
                <TextField
                    required
                    id='outlined-basic'
                    error={editCvFormError.firstName}
                    value={editCvForm.firstName}
                    onChange={handleCvInputs}
                    name='firstName'
                    label={<FormattedMessage id='firstName' />}
                    variant='outlined'
                />
                <TextField
                    required
                    id='outlined-basic'
                    error={editCvFormError.lastName}
                    value={editCvForm.lastName}
                    onChange={handleCvInputs}
                    name='lastName'
                    label={<FormattedMessage id='lastName' />}
                    variant='outlined'
                />
                <TextField
                    required
                    id='outlined-basic'
                    error={editCvFormError.email}
                    value={editCvForm.email}
                    onChange={handleCvInputs}
                    name='email'
                    label='Email'
                    variant='outlined'
                    sx={{ width: 280 }}
                />
            </div>
            <div>
                <TextField
                    required
                    id='outlined-basic'
                    error={editCvFormError.phoneNumber}
                    value={editCvForm.phoneNumber}
                    onChange={handleCvInputs}
                    name='phoneNumber'
                    label={<FormattedMessage id='phonenumber' />}
                    variant='outlined'
                />
                <TextField
                    required
                    id='outlined-basic'
                    error={editCvFormError.locationCountry}
                    value={editCvForm.location.country}
                    onChange={handleCvInputs}
                    name='country'
                    label={<FormattedMessage id='country' />}
                    variant='outlined'
                />
                <TextField
                    required
                    id='outlined-basic'
                    error={editCvFormError.locationCity}
                    value={editCvForm.location.city}
                    onChange={handleCvInputs}
                    name='city'
                    label={<FormattedMessage id='city' />}
                    variant='outlined'
                />
            </div>
        </>
    )
}

export default PersonalInformation