import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal'
import { FormattedMessage } from 'react-intl'
import { globalSliceSelector } from '../../app/slice'
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
}

export default function ExitCVModal(props) {
    const navigate = useNavigate()
    const { isModalOpen, setIsModalOpen } = props
    const { language } = useSelector(globalSliceSelector)

    const handleClose = () => {
        setIsModalOpen(false)
    }

    const handleExit = () => {
        props.resetFormState()
        navigate('/cvs')
    }

    const exitCreateCvMessage = language === 'de' ?
        "Bist du dir sicher, dass du die Seite verlassen möchtest?"
        :
        "Are you sure you want to leave the Page?"

    return (
        <div>
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Box
                        sx={{ display: 'flex', flexDirection: 'column', }}>
                        <Typography component='h1' variant='h5' textAlign='center'>
                            {exitCreateCvMessage}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', mt: 1 }} >
                            <Button variant='contained' sx={{ mt: 3, mb: 2 }} onClick={handleClose}>
                                <FormattedMessage id='no' />
                            </Button>
                            <Button variant='contained' sx={{ mt: 3, mb: 2, bgcolor: 'primary.dark' }} onClick={handleExit}              >
                                <FormattedMessage id='yes' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}
