import { createSlice } from '@reduxjs/toolkit'

const COMPANIES_SLICE = 'COMPANIES_SLICE'

export const companiesSlice = createSlice({
  name: COMPANIES_SLICE,
  initialState: {
    companies: [],
    totalCompanies: 0,
    filterOptions: {
      page: 0,
      pageSize: 10,
      sortField: 'companyName',
      sortOrder: 1,
      searchValue: '',
      showAll: false,
    },
  },
  reducers: {
    setCompanies: (state, action) => {
      state.companies = action.payload
    },
    editAdmins: (state, action) => {
      state.admins = state.admins.map(admin =>
        admin.id === action.payload._id ? { ...admin, ...action.payload } : admin
      )
    },
    editCompanies: (state, action) => {
      state.companies = state.companies.map(company =>
        company.id === action.payload._id ? { ...company, ...action.payload } : company
      )
    },
    removeCompany: (state, action) => {
      state.companies = state.companies.filter(company =>
        company.id !== action.payload
      )
      state.totalCompanies -= 1

    },
    setTotalCompanies: (state, action) => {
      state.totalCompanies = action.payload
    },
    setFilterOptions: (state, action) => {
      const { key, value } = action.payload;
      if (state.filterOptions.hasOwnProperty(key)) {
        state.filterOptions[key] = value;
      }
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload

    },
    setEditedCompany: (state, action) => {
      const { key, value } = action.payload;
      state.selectedCompany[key] = value;
      
    },
    setSelectedCompanyCredits: (state, action) => {
      state.selectedCompany.credits = action.payload
    },
    fetchAllCompanies: () => { },
    fetchSelectedCompany: () => { },
    postCompanyInfo: () => { },
    postCompanyCredits: () => { },
    deleteCompany: () => {},
  },
})

export const {
  setCompanies,
  setTotalCompanies,
  setFilterOptions,
  editCompanies,
  setEditedCompany,
  setCompanyNames,
  setSelectedCompany,
  setSelectedCompanyCredits,
  fetchAllCompanies,
  removeCompany,
  fetchSelectedCompany,
  postCompanyCredits,
  postCompanyInfo,
  deleteCompany
} = companiesSlice.actions
export const companiesSliceSelector = (state) => state.companiesState

export default companiesSlice.reducer
