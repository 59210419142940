import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { deletePackage, fetchAllPackages, packageSliceSelector, setSelectedPackage } from './packagesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Button, Container, capitalize } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import CreatePackageModal from './CreatePackageModal';
import EditIcon from '@mui/icons-material/Edit';
import EditPackageModal from './EditPackageModal';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import DeleteIcon from '@mui/icons-material/Delete';

export default function PackagesPage() {
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const { packages, selectedPackage } = useSelector(packageSliceSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (packages.length === 0) {
            dispatch(fetchAllPackages());
        }
    }, [])

    const closeModal = () => {
        setOpenAddModal(false)
        setOpenEditModal(false)

    };

    const handleEditModalOpen = (data) => {
        dispatch(setSelectedPackage(data))
        setOpenEditModal(true)
    };
    
    const handleDelete = (data) => {
        dispatch(setSelectedPackage(data))
        setOpenDeleteModal(true)
    };
    

    const confirmPackageDelete = () => {
        dispatch(deletePackage(selectedPackage?._id))
        setOpenDeleteModal(false);
    }

    const cancelPackageDelete = () => {
        setOpenDeleteModal(false);
    }


    return (
        <>
            <Container maxWidth="md" sx={{
                maxWidth: 500,
                mx: 'auto', // Centers the container
                py: 10,
                px: 2,
                '@media (min-width: 600px)': {
                    px: 3, // More padding on larger screens
                },
            }}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead sx={{
                            '.MuiTableCell-head': {
                                backgroundColor: 'rgb(235,64,90)',
                                color: '#fff',
                                fontWeight: 'bold',
                            },
                        }}>
                            <TableRow>
                                <TableCell align='center'><FormattedMessage id="offers" /></TableCell>
                                <TableCell align='center'><FormattedMessage id="credits" /></TableCell>
                                <TableCell align='center'><FormattedMessage id="price" /></TableCell>
                                <TableCell align='center'><FormattedMessage id="action" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {packages?.length > 0 ? (
                                packages.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align='center'>{capitalize(row.title)}</TableCell>
                                        <TableCell align='center'>{row.credits}</TableCell>
                                        <TableCell align='center'>{row.price}€</TableCell>
                                        <TableCell align='center'>
                                            <Button
                                                sx={{ m: 0.5, minWidth: 30 }}
                                                variant="outlined"
                                                onClick={() => handleEditModalOpen(row)}
                                            >
                                                <EditIcon />
                                            </Button>
                                            <Button
                                                sx={{ m: 0.5, minWidth: 30 }}
                                                variant="outlined"
                                                onClick={() => handleDelete(row)}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        <FormattedMessage id="noPackagesAvailable" />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button onClick={() => setOpenAddModal(true)} variant="contained" mt={3} sx={{ float: 'right', mt: 2 }}><FormattedMessage id="createPackage" /></Button>
            </Container>
            <CreatePackageModal open={openAddModal} close={closeModal} />
            <EditPackageModal open={openEditModal} close={closeModal} />
            <ConfirmationDialog
                open={openDeleteModal}
                agreeCallBack={confirmPackageDelete}
                disagreeCallback={cancelPackageDelete}
                title={"delete.dialog.package.title"}
                message={"delete.dialog.package.message"}
                messageValue={capitalize(selectedPackage?.title)}
            />
        </>
    );
}