import { createSlice } from '@reduxjs/toolkit'

const PACKAGES_SLICE = 'PACKAGES_SLICE'

export const packagesSlice = createSlice({
    name: PACKAGES_SLICE,
    initialState: {
        packages: [],
        selectedPackage: {
            _id: '',
            title: '',
            credits: null,
            price: null,
        },
    },
    reducers: {
        setPackages: (state, action) => {
            state.packages = action.payload
        },
        editPackages: (state, action) => {
            state.packages = state.packages.map(packageItem =>
                packageItem._id === action.payload._id ? { ...packageItem, ...action.payload } : packageItem
            )
        },
        removePackage: (state, action) => {
            console.log('Removing package', action)
            state.packages = state.packages.filter(packageItem =>
                packageItem._id !== action.payload
            )
        },
        addPackage: (state, action) => {
            const packageItem = { ...action.payload, id: action.payload.id };
            state.packages = [...state.packages, packageItem]
        },
        setSelectedPackage: (state, action) => {
            state.selectedPackage = action.payload
        },
        fetchAllPackages: () => { },
        fetchSelectedPackage: () => { },
        postPackage: () => { },
        putPackage: () => { },
        deletePackage: () => { },
    },
})

export const {
    setPackages, editPackages, removePackage, addPackage,
    fetchAllPackages, postPackage, putPackage, deletePackage, setSelectedPackage
} = packagesSlice.actions
export const packageSliceSelector = (state) => state.packagesState

export default packagesSlice.reducer
