import { createTheme } from '@mui/material/styles';
import { deDE } from '@mui/x-data-grid';

const generateTheme = (language) => {
  return createTheme({
    palette: {
      primary: {
        main: 'rgb(235,64,90)',
        dark: 'rgb(53,53,53, 0.9)',
        light: 'rgb(255,88,88)',
      },
      secondary: {
        main: '#FAF9F6',
        dark: '#353535',
      },
      success: {
        main: '#353535',
        dark: 'rgb(255,88,88)',
        light: 'rgb(255,88,88)',
      },
      lastContact: {
        green: '#A8DF8E',
        yellow: '#F8E559',
        red: '#B80000'
      }
    },
  },
    language === 'de' && deDE
  );
}
export default generateTheme;