import { createSlice } from '@reduxjs/toolkit'

const ADMINS_SLICE = 'ADMINS_SLICE'

export const adminsSlice = createSlice({
  name: ADMINS_SLICE,
  initialState: {
    admins: [],
    totalAdmins: 0,
    filterOptions: {
      page: 0,
      pageSize: 10,
      sortField: 'lastName',
      sortOrder: 1,
      searchValue: '',
      showAll: false,
    },
    selectedAdmin: {
      _id: '',
      firstName: '',
      lastName: '',
      email: '',
    },
  },
  reducers: {
    setAdmins: (state, action) => {
      state.admins = action.payload
    },
    editAdmins: (state, action) => {
      state.admins = state.admins.map(admin =>
        admin.id === action.payload._id ? { ...admin, ...action.payload } : admin
      )
    },
    removeAdmin: (state, action) => {
      state.admins = state.admins.filter(admin =>
        admin.id !== action.payload
      )
      state.totalAdmins -= 1;

    },
    addAdmin: (state, action) => {
      let admin ={ ...action.payload.admin, id: action.payload.id};
      state.admins = [...state.admins, admin]
    },
    setTotalAdmins: (state, action) => {
      state.totalAdmins = action.payload
    },
    setFilterOptions: (state, action) => {
      const { key, value } = action.payload;
      if (state.filterOptions.hasOwnProperty(key)) {
        state.filterOptions[key] = value;
      }
    },
    setSelectedAdmin: (state, action) => {
      state.selectedAdmin = action.payload
    },
    setEditedAdmin: (state, action) => {
      const { key, value } = action.payload;
      if (state.selectedAdmin.hasOwnProperty(key)) {
        state.selectedAdmin[key] = value;
      }
    },
    fetchAllAdmins: () => { },
    fetchSelectedAdmin: () => { },
    postAdminInfo: () => { },
    putAdminInfo: () => { },
    resetAdminPass: () => { },
    deleteAdmin: () => { },
  },
})

export const {
  setAdmins,editAdmins,removeAdmin, addAdmin, setTotalAdmins, setFilterOptions, setSelectedAdmin,
  setEditedAdmin, fetchAllAdmins,resetAdminPass, fetchSelectedAdmin, postAdminInfo, putAdminInfo, deleteAdmin
} = adminsSlice.actions
export const adminsSliceSelector = (state) => state.adminsState

export default adminsSlice.reducer
