import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal'
import { FormattedMessage } from 'react-intl'
import { globalSliceSelector } from '../../app/slice';
import { deleteProExp } from '../createCVComponent/createCVSlice';
import { deleteProExpEditCv } from '../editCvComponent/editCvSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

export default function DeleteAddedJobModal(props) {
  const { showDeleteModal, setShowDeleteModal, jobToDelete, indexOfJobToDelete, type } = props
  const dispatch = useDispatch()
  const { language } = useSelector(globalSliceSelector);

  const handleClose = () => {
    setShowDeleteModal(false)
  }

  const deleteJob = () => {
    if (type === 'createCV') {
      dispatch(deleteProExp(indexOfJobToDelete))
      setShowDeleteModal(false)
    }
    if (type === 'editCV') {
      dispatch(deleteProExpEditCv(indexOfJobToDelete))
      setShowDeleteModal(false)
    }
  }

  const deleteJobMessage = language === 'de' ? `Willst du den ${jobToDelete?.companyName} Job löschen?` : `Are you sure you want to delete the ${jobToDelete?.companyName} job`

  return (
    <div>
      <Modal
        open={showDeleteModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', }}>
            <Typography component='h1' variant='h5'>
              {deleteJobMessage}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', mt: 1 }} >
              <Button variant='contained' sx={{ mt: 3, mb: 2, bgcolor: 'primary.dark' }} onClick={() => handleClose()}              >
                <FormattedMessage id='close' />
              </Button>
              <Button variant='contained' sx={{ mt: 3, mb: 2 }} onClick={() => deleteJob()}>
                <FormattedMessage id='remove' />
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
