import {
    getAdminById,
    getAllAdmins,
    createAdmin,
    updateAdminInfo,
    resetSuperAdminPassword,
    deleteAdminCall,
} from '../../utils/requests/apiCalls'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import { toast } from 'react-toastify';
import { adminsSliceSelector, putAdminInfo, resetAdminPass, fetchAllAdmins, fetchSelectedAdmin, postAdminInfo, setAdmins, setSelectedAdmin, setTotalAdmins, deleteAdmin, removeAdmin } from './adminSlice';

function* fetchAllAdminsSaga(action) {
    try {
        const { filterOptions } = yield select(adminsSliceSelector);

        const res = yield call(getAllAdmins, filterOptions)
        yield put(setAdmins(res.admins))
        yield put(setTotalAdmins(res.count))

    } catch (error) { }
}

function* fetchSelectedAdminSaga(action) {
    try {
        const res = yield call(getAdminById, action.payload)
        if (!res.error) {
            yield put(setSelectedAdmin(res.data[0]))
        }
    } catch (error) {
        console.log('error', error)
    }
}

function* postAdminInfoSaga(action) {
    try {
        const res = yield call(createAdmin, action.payload)

        if (!res.error) {
            yield fetchAllAdminsSaga()
            toast.success('Admin created successfully')
        }
    } catch (error) {
        toast.error(error.message)
        console.log('error', error)
    }
}

function* putAdminInfoSaga(action) {
    try {
        const res = yield call(updateAdminInfo, action.payload)

        if (res.statusCode === 200) {
            yield put(setSelectedAdmin(res.data))
            yield fetchAllAdminsSaga()
            toast.success('User updated successfully')
        }
    } catch (error) {
        console.log('error', error)
    }
}

function* resetAdminPassSaga(action) {
    try {
        const res = yield call(resetSuperAdminPassword, action.payload)

        if (res.statusCode === 200) {
            toast.success('User password updated successfully')
        }
    } catch (error) {
        console.log('error', error)
    }
}

function* deleteAdminSaga(action) {
    try {
        const res = yield call(deleteAdminCall, action.payload)

        if (res.statusCode === 200) {
            yield put(setSelectedAdmin({}))
            yield put(removeAdmin(action.payload))
            toast.success(res.message)
        }
    } catch (error) {
        console.log('error', error)
    }
}

export function* watchAdminsSaga() {
    yield takeLatest(fetchAllAdmins.type, fetchAllAdminsSaga)
    yield takeLatest(fetchSelectedAdmin.type, fetchSelectedAdminSaga)
    yield takeLatest(postAdminInfo.type, postAdminInfoSaga)
    yield takeLatest(putAdminInfo.type, putAdminInfoSaga)
    yield takeLatest(resetAdminPass.type, resetAdminPassSaga)
    yield takeLatest(deleteAdmin.type, deleteAdminSaga)
}
