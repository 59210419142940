import React, { useState } from 'react';
import { Button, Stack, TextField, Container, Typography, Modal, InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { postPackage } from './packagesSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

const initialState = {
  title: '',
  credits: '',
  price: '',
}

const CreatePackageModal = ({ open, close }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [formData, setFormData] = useState(initialState);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'credits' || name === 'price') {
      const re = /^[0-9\b]+$/; //allow only numbers
      if (value === '' || re.test(value)) {
        setFormData({ ...formData, [name]: Number(value) });
      }
    } else {
      setFormData({ ...formData, [name]: value });

    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postPackage(formData))
    close(false);
    setFormData(initialState)

  };

  const closeModal = () => {
    setFormData(initialState)
    //Pass false to parent state: openModal
    close(false);
  }

  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Container component="main" maxWidth="xs" sx={style}>
        <Typography variant="h5" align="center" mb={3}>
          <FormattedMessage id="createPackageForm" />
        </Typography>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: "package.title" })}
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
              m={2}
            />
            <TextField
              fullWidth
              label={intl.formatMessage({ id: "credits" })}
              name="credits"
              value={formData.credits}
              onChange={handleChange}
              required
              p={2}
            />
            <TextField
              fullWidth
              label={intl.formatMessage({ id: "price" })}
              type="price"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
              m={2}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <Stack direction='row' spacing={3}>
              <Button variant="contained" color="success" fullWidth onClick={closeModal}>
                <FormattedMessage id="cancel" />
              </Button>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                <FormattedMessage id="save" />
              </Button>
            </Stack>
          </Stack>
        </form>
      </Container>
    </Modal>
  );
};

export default CreatePackageModal;
