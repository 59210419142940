import React, { useState } from 'react';
import { Button, Stack, TextField, Container, Typography, Modal, TextareaAutosize } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deleteCV } from '../editCvComponent/editCvSlice';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
}

const DeleteCVModal = ({ open, close, id }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [formData, setFormData] = useState({
        deletionReason: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setOpenDeleteDialog(true);
    };

    const closeModal = () => {
        close(false);
    };

    const deleteConfirmation = () => {
        dispatch(deleteCV({ id, deletionReason: formData.deletionReason, navigate }))
    }

    const deleteCancelation = () => {
        setOpenDeleteDialog(false);
    }


    return (
        <><Modal
            open={open}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Container component="main" maxWidth="xs" sx={style}>
                <Typography variant="h5" align="center" mb={3}>
                    <FormattedMessage id="deleteCVBtn" />
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Typography variant="body1" mb={3} align='center'>
                        {intl.formatMessage({ id: "delete.cv.modal" })}:
                    </Typography>
                    <Stack spacing={2}>
                        <TextField
                            fullWidth
                            name="deletionReason"
                            multiline
                            label={intl.formatMessage({ id: "deletionReason" })}
                            minRows={2}
                            value={formData.deletionReason}
                            onChange={handleChange}
                            required
                            p={2} />
                        <Stack direction='row' spacing={3}>
                            <Button variant="contained" color="success" fullWidth onClick={closeModal}>
                                <FormattedMessage id="cancel" />
                            </Button>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                <FormattedMessage id="continue" />
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Container>
        </Modal>
            <ConfirmationDialog
                title="deleteCVBtn"
                message="delete.cv.dialog.message"
                messageValue={'Here was the Ref Number'}
                agreeCallBack={deleteConfirmation}
                disagreeCallback={deleteCancelation}
                open={openDeleteDialog} />
        </>
    );
};

export default DeleteCVModal;
