import {
    getAllPackages,
    createPackage,
    deletePackageCall,
    updatePackage,
} from '../../utils/requests/apiCalls'
import { takeLatest, put, call } from 'redux-saga/effects'
import { toast } from 'react-toastify';
import { addPackage, deletePackage, editPackages, fetchAllPackages, postPackage, putPackage, removePackage, setPackages } from './packagesSlice';

function* fetchAllPackagesSaga(action) {
    try {
        const res = yield call(getAllPackages)
        yield put(setPackages(res.data))

    } catch (error) {
        console.log('error', error)
        toast.error('Could not load packages')
    }
}

function* postPackageSaga(action) {
    try {
        const res = yield call(createPackage, action.payload)

        if (!res.error) {
            yield put(addPackage(res.data))
            toast.success('Package created successfully')
        }
    } catch (error) {
        toast.error(error.message)
        console.log('error', error)
    }
}

function* putPackageSaga(action) {
    try {
        const res = yield call(updatePackage, action.payload)

        if (res.statusCode === 200) {
            yield put(editPackages(res.data))
            toast.success('Package updated successfully')
        }
    } catch (error) {
        toast.error(error.message)
        console.log('error', error)
    }
}


function* deletePackageSaga(action) {
    try {
        const res = yield call(deletePackageCall, action.payload)

        if (res.statusCode === 200) {
            yield put(removePackage(action.payload))
            toast.success(res.message)
        }
    } catch (error) {
        console.log('error', error)
        toast.error(error.message)
    }
}

export function* watchPackagesSaga() {
    yield takeLatest(fetchAllPackages.type, fetchAllPackagesSaga)
    yield takeLatest(postPackage.type, postPackageSaga)
    yield takeLatest(putPackage.type, putPackageSaga)
    yield takeLatest(deletePackage.type, deletePackageSaga)
}
