import { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { authSliceSelector, setSignOutUser } from '../authComponent/authSlice'
import { useDispatch } from 'react-redux'
import Select from '@mui/material/Select'
import { globalSliceSelector, setSelectedLanguage } from '../../app/slice'
import './../../style/style.css';
import { useIntl } from 'react-intl'

export default function Header() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { authenticated, firstName, lastName } = useSelector(authSliceSelector)
  const { language } = useSelector(globalSliceSelector);

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  function createLettersForHeaderIcon() {
    // Take the first letter from firstname surname
    if (!firstName || !lastName) {
      return ''
    }

    const firstTwoLetters = firstName?.slice(0, 1) + lastName?.slice(0, 1)
    const capitalizedResult = firstTwoLetters?.toUpperCase()
    return capitalizedResult
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSignOut = () => {
    dispatch(setSignOutUser())
  }

  const handleLanguageChange = (e) => {
    dispatch(setSelectedLanguage(e.target.value))
  }


  return (
    <AppBar position="static" sx={{ bgcolor: 'white' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant='h5'
            color='primary.dark'
            sx={{ flexGrow: 1, fontWeight: 'bold' }}
          >
            <img style={{ height: 50, width: 90 }} src='/logo.png' alt="MAISOURCER"></img>
          </Typography>
          {authenticated &&
            (
              <Box sx={{ flexGrow: 20, display: 'flex', justifyContent: 'start', width: 200 }}>
                <Stack direction='row' spacing={2} >
                  <NavLink
                    exact
                    to='/'
                    style={({ isActive }) => ({
                      color: isActive ? "rgb(235,64,90)" : "#353535", textDecoration: 'none', fontSize: 18,
                    })}
                  >
                    {intl.formatMessage({ id: "companies" }).toUpperCase()}
                  </NavLink>
                  <NavLink
                    to='/cvs'
                    style={({ isActive }) => ({
                      color: isActive ? "rgb(235,64,90)" : "#353535", textDecoration: 'none', fontSize: 18
                    })}
                  >
                    CV's
                    {/* here */}
                  </NavLink>
                  <NavLink
                    to='/admins'
                    style={({ isActive }) => ({
                      color: isActive ? "rgb(235,64,90)" : "#353535", textDecoration: 'none', fontSize: 18
                    })}
                  >
                    {intl.formatMessage({ id: "adminUsers" }).toUpperCase()}
                  </NavLink>
                  <NavLink
                    to='/packages'
                    style={({ isActive }) => ({
                      color: isActive ? "rgb(235,64,90)" : "#353535", textDecoration: 'none', fontSize: 18
                    })}
                  >
                    CREDITS
                  </NavLink>
                </Stack>
              </Box>
            )
          }
          {authenticated ? (
            <div>
              <Box
                sx={{
                  p: 1,
                  m: 2,
                  backgroundColor: 'grey',
                  borderRadius: '125%',
                  cursor: 'pointer',
                  flexGrow: 1,
                  display: { xs: 'none', md: 'flex' }
                }}
                onClick={handleClick}
                children={createLettersForHeaderIcon()}
              />
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleSignOut}>Logout</MenuItem>
              </Menu>
            </div>
          ) : (
            <NavLink to='sign-in'
              style={({ isActive }) => ({
                color: isActive ? "rgb(235,64,90)" : "#353535", textDecoration: 'none', fontSize: 18
              })}    >
              SIGN IN
            </NavLink>

          )}
          <Select
            sx={{ maxHeight: 30, maxWidth: 100, m: 1 }}
            value={language}
            onChange={handleLanguageChange}
          >
            <MenuItem value={'en'}>EN</MenuItem>
            <MenuItem value={'de'}>DE</MenuItem>
          </Select>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
