import { call, put, select, takeLatest } from "redux-saga/effects";
import {
    addNewProExp,
    addPrimarySkill,
    addSecondarySkill,
    addlanguage,
    deleteCV,
    deleteLanguage,
    deletePrimarySkill,
    deleteProExpEditCv,
    deleteSecondarySkill,
    editCvSliceSelector,
    findCV,
    resetForm,
    saveEditedJob,
    setEditCvForm,
    setEditCvFormError,
    setEditableRowIndex,
    setErrorEditJobForm,
    setErrorProExp,
    setLanguageTextInput,
    setLastSavedStateEditCvForm,
    setPrimarySkillTextInput,
    setProExperienceForm,
    setSecondarySkillTextInput,
    setShowViewEditCvModal,
    updateCv,
    validateCv
} from "./editCvSlice";
import { cvsSliceSelector, removeCV, setCvs } from "../cvsComponent/CVsSlice";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { initialState, initialStateNewProExperience } from "../createCVComponent/createCvInitialState";
import { deleteCVCall, getSortedCvForAdminCall, updateCvCall } from "../../utils/requests/apiCalls";

function* findCVSaga(action) {
    const { editCvForm } = yield select(editCvSliceSelector)
    const cvId = action.payload

    const foundCV = yield call(getSortedCvForAdminCall, cvId)

    if (foundCV.error === false) {
        yield put(setEditCvForm({ ...editCvForm, ...foundCV.data[0] }))
        yield put(setLastSavedStateEditCvForm({ ...editCvForm, ...foundCV.data[0] }))
    } else {
        toast.error(<FormattedMessage id='error.somethingWentWrong' />)
    }
}

function* addNewProExpSaga() {
    const { editCvForm, proExperienceForm } = yield select(editCvSliceSelector)
    // --- Validate
    const newError = {
        companyName: !proExperienceForm.companyName,
        start: !proExperienceForm.start,
        title: !proExperienceForm.title,
        companyType: !proExperienceForm.companyType,
    }

    yield put(setErrorProExp(newError))
    const hasNewError = Object.entries(newError).find(pair => pair[1])
    const isStartDateBiggerThanEndDate = proExperienceForm.end ? new Date(proExperienceForm.start) > new Date(proExperienceForm.end) : false
    const isStartDateInTheFuture = new Date(proExperienceForm.start) > new Date()
    const isEndDateInTheFuture = new Date(proExperienceForm.end) > new Date()

    if (hasNewError || isStartDateBiggerThanEndDate || isStartDateInTheFuture || isEndDateInTheFuture) {
        hasNewError && toast.error(<FormattedMessage id='createCv.error.addProExperience' />)
        isStartDateBiggerThanEndDate && toast.error(<FormattedMessage id='createCv.error.startDateBiggerThanEndDate' />)
        isStartDateInTheFuture && toast.error(<FormattedMessage id='createCv.error.startDateInFuture' />)
        isEndDateInTheFuture && toast.error(<FormattedMessage id='createCv.error.endDateInFuture' />)
        return
    }
    // --- Validate
    yield put(setEditCvForm({ ...editCvForm, proExperience: [...editCvForm.proExperience, proExperienceForm] }))
    yield put((setProExperienceForm(initialStateNewProExperience)))
}

function* deleteProExpEditCvSaga(action) {
    const { editCvForm } = yield select(editCvSliceSelector)
    const index = action.payload
    const newArray = editCvForm.proExperience.filter((p, i) => index !== i)
    yield put(setEditCvForm({ ...editCvForm, proExperience: newArray }))
}

function* saveEditedJobSaga() {
    const { editJobForm, editCvForm, editableRowIndex } = yield select(editCvSliceSelector)
    // -- Validate
    const newError = {
        companyName: !editJobForm.companyName,
        start: !editJobForm.start,
        title: !editJobForm.title,
        companyType: !editJobForm.companyType,
    }

    yield put(setErrorEditJobForm(newError))
    const hasNewError = Object.entries(newError).find(pair => pair[1])
    const isStartDateBiggerThanEndDate = editJobForm.end ? new Date(editJobForm.start) > new Date(editJobForm.end) : false
    const isStartDateInTheFuture = new Date(editJobForm.start) > new Date()
    const isEndDateInTheFuture = new Date(editJobForm.end) > new Date()

    if (hasNewError || isStartDateBiggerThanEndDate || isStartDateInTheFuture || isEndDateInTheFuture) {
        hasNewError && toast.error(<FormattedMessage id='createCv.error.addProExperience' />)
        isStartDateBiggerThanEndDate && toast.error(<FormattedMessage id='createCv.error.startDateBiggerThanEndDate' />)
        isStartDateInTheFuture && toast.error(<FormattedMessage id='createCv.error.startDateInFuture' />)
        isEndDateInTheFuture && toast.error(<FormattedMessage id='createCv.error.endDateInFuture' />)
        return
    }
    // -- Validate

    const newProExperienceArray = editCvForm.proExperience.map((exp, i) => i === editableRowIndex ? editJobForm : exp)

    yield put(setEditableRowIndex(null))
    yield put(setEditCvForm({ ...editCvForm, proExperience: newProExperienceArray }))

}

function* addPrimarySkillSaga() {
    const { primarySkillTextInput, editCvForm, editCvFormError } = yield select(editCvSliceSelector)
    if (!primarySkillTextInput) {
        toast.warning(<FormattedMessage id='createCv.error.addEmptyPrimarySkill' />)
        return
    }

    if (editCvFormError.primarySkills) {
        yield put(setEditCvFormError({ ...editCvFormError, primarySkills: false }))
    }

    yield put(setEditCvForm({ ...editCvForm, primarySkills: [...editCvForm.primarySkills, primarySkillTextInput] }))
    yield put(setPrimarySkillTextInput(''))
}

function* deletePrimarySkillSaga(action) {
    const { editCvForm } = yield select(editCvSliceSelector)
    const index = action.payload

    const newArray = editCvForm.primarySkills.filter((skill, i) => index !== i)
    yield put(setEditCvForm({ ...editCvForm, primarySkills: newArray }))
}

function* addSecondarySkillSaga() {
    const { secondarySkillTextInput, editCvForm, editCvFormError } = yield select(editCvSliceSelector)
    if (!secondarySkillTextInput) {
        toast.warning(<FormattedMessage id='createCv.error.addSecondarySkill' />)
        return
    }

    // This might not be needed anymore
    if (editCvFormError.secondarySkills) {
        yield put(setEditCvFormError({ ...editCvFormError, secondarySkills: false }))
    }

    yield put(setEditCvForm({ ...editCvForm, secondarySkills: [...editCvForm.secondarySkills, secondarySkillTextInput] }))
    yield put(setSecondarySkillTextInput(''))
}

function* deleteSecondarySkillSaga(action) {
    const index = action.payload
    const { editCvForm } = yield select(editCvSliceSelector)

    const newArray = editCvForm.secondarySkills.filter((skill, i) => index !== i)
    yield put(setEditCvForm({ ...editCvForm, secondarySkills: newArray }))
}

function* addLanguageSaga() {
    const { languageTextInput, editCvForm, editCvFormError } = yield select(editCvSliceSelector)
    if (!languageTextInput) {
        toast.warning(<FormattedMessage id='createCv.error.addEmptyLanguage' />)
        return
    }
    if (editCvFormError.languages) {
        yield put(setEditCvFormError({ ...editCvFormError, languages: false }))
    }

    yield put(setEditCvForm({ ...editCvForm, languages: [...editCvForm.languages, languageTextInput] }))
    yield put(setLanguageTextInput(''))
}

function* deleteLanguageSaga(action) {
    const { editCvForm } = yield select(editCvSliceSelector)
    const index = action.payload

    const newArray = editCvForm.languages.filter((lan, i) => i !== index)
    yield put(setEditCvForm({ ...editCvForm, languages: newArray }))
}

function* validateCvSaga() {
    const { editCvForm } = yield select(editCvSliceSelector)
    const newError = {
        firstName: !editCvForm.firstName,
        lastName: !editCvForm.lastName,
        email: !editCvForm.email,
        locationCountry: !editCvForm.location.country,
        locationCity: !editCvForm.location.city,
        phoneNumber: !editCvForm.phoneNumber,
        workModel: !editCvForm.workModel,
        yearsOfExperience: isNaN(editCvForm.yearsOfExperience),
        jobTitle: !editCvForm.jobTitle,
        salaryRatePerHour: isNaN(editCvForm.salaryRatePerHour),
        level: !editCvForm.level,
        startInWeeks: isNaN(editCvForm.startInWeeks),
        teaserCompanySize: !editCvForm.teaser.companySize,
        willToTravel: !(typeof editCvForm.willToTravel === 'boolean'),
        primarySkills: editCvForm.primarySkills.length === 0,
        languages: editCvForm.languages.length === 0,
        contractType: !editCvForm.contractType,
        price: isNaN(editCvForm.price),
    }

    const isLastContactInFuture = editCvForm.lastContact !== null && new Date(editCvForm.lastContact) > new Date()

    newError.primarySkills && toast.error(<FormattedMessage id='createCv.error.emptyPrimarySkills' />)
    newError.languages && toast.error(<FormattedMessage id='createCv.error.emptyLanguages' />)

    yield put(setEditCvFormError(newError))

    if (Object.entries(newError).find(pair => pair[1]) || isLastContactInFuture) {
        Object.entries(newError).find(pair => pair[1]) && toast.error(<FormattedMessage id='createCv.error.allFieldsFilled' />)
        isLastContactInFuture && toast.error(<FormattedMessage id='lastContact.error.dateIsInFuture' />)
        return;
    }
    yield put(setShowViewEditCvModal(true))
}

function* updateCvSaga(action) {
    const { editCvForm } = yield select(editCvSliceSelector)
    const { cvs } = yield select(cvsSliceSelector)
    const finalCV = {
        firstName: editCvForm.firstName,
        lastName: editCvForm.lastName,
        email: editCvForm.email,
        location: editCvForm.location,
        phoneNumber: editCvForm.phoneNumber,
        workModel: editCvForm.workModel,
        yearsOfExperience: Number(editCvForm.yearsOfExperience),
        jobTitle: editCvForm.jobTitle,
        salaryRatePerHour: Number(editCvForm.salaryRatePerHour),
        level: editCvForm.level,
        startInWeeks: Number(editCvForm.startInWeeks),
        teaser: editCvForm.teaser,
        willToTravel: editCvForm.willToTravel,
        readyToRelocate: editCvForm.readyToRelocate,
        proExperience: editCvForm.proExperience,
        primarySkills: editCvForm.primarySkills,
        secondarySkills: editCvForm.secondarySkills,
        languages: editCvForm.languages,
        contractType: editCvForm.contractType,
        price: Number(editCvForm.price),
        lastContact: editCvForm.lastContact ? editCvForm.lastContact : null,
    }

    try {
        const cvId = editCvForm._id
        const res = yield call(updateCvCall, cvId, finalCV)
        if (res.statusCode === 200) {
            toast.success(<FormattedMessage id='editCv.updated' />)
            const indexToUpdate = cvs.findIndex(cv => cv._id === cvId)
            const newCVsArray = cvs.map((cv, index) => indexToUpdate === index ? { ...res.data } : { ...cv })
            yield put(setCvs(newCVsArray))
            yield put(setEditCvForm(res.data))
            yield put(setShowViewEditCvModal(false))
            action.payload.navigate('/cvs')
        }
    } catch (error) {
        console.log('error', error)
    }
}

function* deleteCVSaga(action) {
    try {
        const result = yield call(deleteCVCall, action.payload);
        if (result.statusCode === 200) {
            toast.success(result.message)
            yield put(removeCV(action.payload.id))
            yield resetFormSaga()
            action.payload.navigate('/cvs')
        } else {
            toast.error(result.message)
        }
    } catch (error) {
        console.log('deleteCVSaga', error)
    }
}

function* resetFormSaga() {
    yield put(setEditCvForm(initialState))
    yield put(setProExperienceForm(initialStateNewProExperience))
    yield put(setPrimarySkillTextInput(''))
    yield put(setSecondarySkillTextInput(''))
    yield put(setLanguageTextInput(''))
}

export function* watchEditCvSaga() {
    yield takeLatest(findCV.type, findCVSaga)
    yield takeLatest(addPrimarySkill.type, addPrimarySkillSaga)
    yield takeLatest(deletePrimarySkill.type, deletePrimarySkillSaga)
    yield takeLatest(addSecondarySkill.type, addSecondarySkillSaga)
    yield takeLatest(deleteSecondarySkill.type, deleteSecondarySkillSaga)
    yield takeLatest(addlanguage.type, addLanguageSaga)
    yield takeLatest(deleteLanguage.type, deleteLanguageSaga)
    yield takeLatest(addNewProExp.type, addNewProExpSaga)
    yield takeLatest(deleteProExpEditCv.type, deleteProExpEditCvSaga)
    yield takeLatest(updateCv.type, updateCvSaga)
    yield takeLatest(validateCv.type, validateCvSaga)
    yield takeLatest(saveEditedJob.type, saveEditedJobSaga)
    yield takeLatest(resetForm.type, resetFormSaga)
    yield takeLatest(deleteCV.type, deleteCVSaga)
}