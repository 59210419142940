import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormattedMessage } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { editCvSliceSelector, findCV, resetForm, setShowViewEditCvModal, validateCv } from './editCvSlice';
import PersonalInformation from './components/PersonalInformation';
import ProfessionalExperience from './components/ProfessionalExperience'
import Skills from './components/Skills';
import GeneralInformation from './components/GeneralInformation';
import ProfileManagement from './components/ProfileManagement';
import ViewCVModal from '../modals/ViewCVModal';
import ExitCVModal from '../modals/ExitCVModal';
import { initialStateNewProExperience } from '../createCVComponent/createCvInitialState';
import { Link, Stack } from '@mui/material';
import { authSliceSelector } from '../authComponent/authSlice';
import DeleteCVModal from '../modals/DeleteCVModal';

function EditCvPage() {
    const CV_ID = useParams().id
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { editCvForm,
        proExperienceForm,
        primarySkillTextInput,
        secondarySkillTextInput,
        languageTextInput,
        showViewEditCvModal,
        lastSavedStateEditCvForm
    } = useSelector(editCvSliceSelector)
    
    const [openExitCvModal, setOpenExitCvModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleUpdateCvClick = () => {
        dispatch(validateCv())
    }

    const resetFormState = () => {
        dispatch(resetForm())
    }

    const shouldOpenExitModal = () => {
        const hasEditCvFormChange = JSON.stringify(lastSavedStateEditCvForm) !== JSON.stringify(editCvForm)
        const hasAddProExperienceFormChanges = JSON.stringify(proExperienceForm) !== JSON.stringify(initialStateNewProExperience)
        const hasPrimarySkillsTextInputChanges = primarySkillTextInput !== ''
        const hasSecondarySkillsTextInputChanges = secondarySkillTextInput !== ''
        const hasLanguagseTextInputChanges = languageTextInput !== ''

        const hasChanges = hasEditCvFormChange ||
            hasAddProExperienceFormChanges ||
            hasPrimarySkillsTextInputChanges ||
            hasSecondarySkillsTextInputChanges ||
            hasLanguagseTextInputChanges

        return hasChanges
    }

    const handleCloseButton = () => {
        const shouldOpenModal = shouldOpenExitModal()
        if (shouldOpenModal) {
            setOpenExitCvModal(true)
        } else {
            resetFormState()
            navigate('/cvs')
        }
    }

    const closeDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    useEffect(() => {
        dispatch(findCV(CV_ID))
    }, [])

    return (
        <div>
            <h1 style={{ textAlign: 'center', marginBottom: 0 }}><FormattedMessage id='editCv' /></h1>
            <ViewCVModal showCv={showViewEditCvModal} selectedCV={editCvForm} setShowModal={setShowViewEditCvModal} type={'editCv'} />
            <ExitCVModal isModalOpen={openExitCvModal} setIsModalOpen={setOpenExitCvModal} resetFormState={resetFormState} />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
                <Box component='form' sx={{ '& .MuiTextField-root': { m: 1 }, mt: 2, mb: 10, width: '60%' }}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography><FormattedMessage id='personalInformation' /> *</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PersonalInformation />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                            <Typography><FormattedMessage id='professionalExperience' /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ProfessionalExperience />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                            <Typography><FormattedMessage id='skills' /> *</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Skills />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                            <Typography><FormattedMessage id='generalInformation' /> *</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <GeneralInformation />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                            <Typography><FormattedMessage id='profileManagement' /></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ProfileManagement />
                        </AccordionDetails>
                    </Accordion>
                    <Stack direction={'row'} justifyContent='space-between' mt={5}>
                        <Link href="#" onClick={() => setOpenDeleteModal(true)} variant="body1" color='error'>
                            <FormattedMessage id="deleteCVBtn" />
                        </Link>

                        <Box sx={{ display: 'flex', gap: 4 }}>
                            <Button type="button" variant='contained' sx={{ bgcolor: 'primary.dark' }} onClick={handleCloseButton}>
                                <FormattedMessage id='close' />
                            </Button>
                            <Button type="button" variant='contained' onClick={handleUpdateCvClick}>
                                <FormattedMessage id='save' />
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box>

            <DeleteCVModal open={openDeleteModal} close={closeDeleteModal} id={editCvForm?._id} />
        </div>
    )
}

export default EditCvPage