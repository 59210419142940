import React, { useState } from 'react';
import { Button, Stack, TextField, Container, Typography, FormControl, InputLabel, Select, MenuItem, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { adminsSliceSelector, deleteAdmin, putAdminInfo, setEditedAdmin } from './adminSlice';
import ResetPassModal from './ResetPassModal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
}

const CreateAdminModal = ({ open, close }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { selectedAdmin } = useSelector(adminsSliceSelector)
    const [emailError, setEmailError] = useState('');
    const [openPasswordModal, setOpenPasswordModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const validateEmail = (email) => {
        if (email.length === 0 || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            setEmailError(''); // Clear any previous error
        } else {
            setEmailError(<FormattedMessage id="invalidEmailFormat" />);
        }
    };

    const handleChange = (e) => {
        const key = e.target.name;
        const value = e.target.value;

        if (key === "email") {
            dispatch(setEditedAdmin({ key, value }))
            validateEmail(value);
        } else {
            dispatch(setEditedAdmin({ key, value }))
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(putAdminInfo(selectedAdmin))
        close(false);
    };

    const handleEditModalClose = () => {
        setEmailError(''); // Clear any previous error
        //Pass false to parent state: openModal
        close(false);
    }

    const closePasswordModal = () => {
        setOpenPasswordModal(false);
    }

    const confirmAdminDelete = () => {
        dispatch(deleteAdmin(selectedAdmin?._id))
        setOpenDeleteModal(false);
        close(false);
    }

    const cancelAdminDelete = () => {
        setOpenDeleteModal(false);
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Container component="main" maxWidth="xs" sx={style}>
                    <CloseRoundedIcon
                        onClick={handleEditModalClose}
                        sx={{ ml: 46, color: 'grey', alignSelf: 'flex-end', display: 'flex' }}
                    />
                    <Typography variant="h5" align="center" mb={2}>
                        <FormattedMessage id="editAdminForm" />
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: "firstName" })}
                                name="firstName"
                                value={selectedAdmin.firstName}
                                onChange={handleChange}
                                required
                                m={2} />
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: "lastName" })}
                                name="lastName"
                                value={selectedAdmin.lastName}
                                onChange={handleChange}
                                required
                                p={2} />
                            <TextField
                                fullWidth
                                error={!!emailError}
                                helperText={emailError}
                                label="Email"
                                type="email"
                                name="email"
                                value={selectedAdmin.email}
                                onChange={handleChange}
                                required
                                m={2} />
                            <Link href="#" onClick={() => setOpenDeleteModal(true)} variant="body2" color='secondary.dark'>
                                <FormattedMessage id="deleteUserBtn" />
                            </Link>
                            <Stack direction='row' spacing={3}>
                                <Button variant="contained" color="success" fullWidth onClick={() => setOpenPasswordModal(true)}>
                                    <FormattedMessage id="resetPassword" />
                                </Button>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    <FormattedMessage id="save" />
                                </Button>
                            </Stack>

                        </Stack>
                    </form>
                </Container>
            </Modal >
            <ResetPassModal open={openPasswordModal} close={closePasswordModal} adminId={selectedAdmin._id} />
            <ConfirmationDialog
                open={openDeleteModal}
                agreeCallBack={confirmAdminDelete}
                disagreeCallback={cancelAdminDelete}
                title={"delete.dialog.user.title"}
                message={"delete.dialog.user.message"}
                messageValue={selectedAdmin?.firstName + " " + selectedAdmin?.lastName}
            />
        </>
    );
};

export default CreateAdminModal;
