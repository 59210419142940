import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createCvSliceSelector, setFormState } from '../createCVSlice';

function GeneralInformation() {
    const dispatch = useDispatch()
    const { formState, error } = useSelector(createCvSliceSelector)

    const handleCvInputs = (e) => {
        const key = e.target.name
        const value = e.target.value
        if (key === 'areaOfApplication') {
            dispatch(setFormState({ ...formState, location: { ...formState.location, [key]: value, }, }))
        } else if (key === 'description' || key === 'companySize') {
            dispatch(setFormState({ ...formState, teaser: { ...formState.teaser, [key]: value, }, }))
        } else {
            dispatch(setFormState({ ...formState, [key]: value }))
        }
    }

    return (
        <>
            <div>
                <FormControl error={error.contractType} sx={{ m: 1, width: 195 }} required>
                    <InputLabel id='demo-simple-select-autowidth-label'><FormattedMessage id='contractType' /></InputLabel>
                    <Select
                        labelId='demo-simple-select-autowidth-label'
                        id='demo-simple-select-autowidth'
                        autoWidth
                        label={<FormattedMessage id='contractType' />}
                        name='contractType'
                        value={formState.contractType}
                        onChange={handleCvInputs}
                    >
                        <MenuItem value={'employment'}><FormattedMessage id='contractType.employment' /></MenuItem>
                    </Select>
                </FormControl>
                <FormControl error={error.workModel} sx={{ m: 1, width: 195 }} required>
                    <InputLabel id='demo-simple-select-autowidth-label'>
                        <FormattedMessage id='workModel' />
                    </InputLabel>
                    <Select
                        labelId='demo-simple-select-autowidth-label'
                        id='demo-simple-select-autowidth'
                        autoWidth
                        label={<FormattedMessage id='workModel' />}
                        name='workModel'
                        value={formState.workModel}
                        onChange={handleCvInputs}
                    >
                        <MenuItem value={'remote'}>Remote</MenuItem>
                        <MenuItem value={'hybrid'}>Hybrid</MenuItem>
                        <MenuItem value={'on-site'}>{<FormattedMessage id='on-site' />}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl error={error.teaserCompanySize} sx={{ m: 1, width: 195 }} required>
                    <InputLabel id='demo-simple-select-autowidth-label'><FormattedMessage id='companySize' /></InputLabel>
                    <Select
                        labelId='demo-simple-select-autowidth-label'
                        id='demo-simple-select-autowidth'
                        autoWidth
                        label={<FormattedMessage id='companySize' />}
                        name='companySize'
                        value={formState.teaser.companySize}
                        onChange={handleCvInputs}
                    >
                        <MenuItem value={'flexible'}><FormattedMessage id='companySize.flexible' /></MenuItem>
                        <MenuItem value={'startup'}>Start-Up</MenuItem>
                        <MenuItem value={'small-mid'}><FormattedMessage id='companySize.smallMid' /></MenuItem>
                        <MenuItem value={'group'}><FormattedMessage id='companySize.group' /></MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id='outlined-basic'
                    error={error.locationAreaOfApplication}
                    value={formState.location.areaOfApplication}
                    onChange={handleCvInputs}
                    name='areaOfApplication'
                    label={<FormattedMessage id='areaOfApplication' />}
                    variant='outlined'
                />
            </div>
            <Stack direction={'row'}>
                <TextField
                    id='outlined-basic'
                    error={error.salaryRatePerHour}
                    label={<FormattedMessage id='salaryPerHour' />}
                    variant='outlined'
                    name='salaryRatePerHour'
                    value={formState.salaryRatePerHour}
                    onChange={handleCvInputs}
                />
                <TextField
                    id='outlined-basic'
                    error={error.startInWeeks}
                    label={<FormattedMessage id='startInWeeks' />}
                    variant='outlined'
                    name='startInWeeks'
                    value={formState.startInWeeks}
                    onChange={handleCvInputs}
                />
                <Stack direction={'row'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name='willToTravel'
                                checked={formState.willToTravel}
                                onChange={() => {
                                    dispatch(setFormState({ ...formState, willToTravel: !formState.willToTravel }))
                                }}
                            />
                        }
                        label={<FormattedMessage id='willToTravel' />}
                        sx={{ ml: 1.5 }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name='readyToRelocate'
                                checked={formState.readyToRelocate}
                                onChange={() => {
                                    dispatch(setFormState({ ...formState, readyToRelocate: !formState.readyToRelocate }))
                                }}
                            />
                        }
                        label={<FormattedMessage id='readyToRelocate' />}
                        sx={{ ml: 1.5 }}
                    />
                </Stack>
            </Stack>
            <div>
                <TextField
                    required
                    id='outlined-basic'
                    error={error.jobTitle}
                    label={<FormattedMessage id='jobTitle' />}
                    variant='outlined'
                    name='jobTitle'
                    value={formState.jobTitle}
                    onChange={handleCvInputs}
                />
                <FormControl error={error.level} sx={{ m: 1, width: 195 }} required>
                    <InputLabel id='demo-simple-select-autowidth-label'>Level</InputLabel>
                    <Select
                        labelId='demo-simple-select-autowidth-label'
                        id='demo-simple-select-autowidth'
                        autoWidth
                        label='Level'
                        name='level'
                        value={formState.level}
                        onChange={handleCvInputs}
                    >
                        <MenuItem value={'junior'}>Junior</MenuItem>
                        <MenuItem value={'mid'}>Mid</MenuItem>
                        <MenuItem value={'senior'}>Senior</MenuItem>
                        <MenuItem value={'lead'}>Lead</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    id='outlined-basic'
                    error={error.yearsOfExperience}
                    label={<FormattedMessage id='yearsOfExperience' />}
                    variant='outlined'
                    value={formState.yearsOfExperience}
                    onChange={handleCvInputs}
                    name='yearsOfExperience'
                />
            </div>
            <Box>
                <TextField
                    fullWidth
                    id='outlined-basic'
                    error={error.teaserDescription}
                    label={<FormattedMessage id='description' />}
                    name='description'
                    variant='outlined'
                    multiline
                    minRows={10}
                    maxRows={24}
                    value={formState.teaser.description}
                    onChange={handleCvInputs}
                    placeholder='Add a full Description'
                />
            </Box>
        </>
    )
}

export default GeneralInformation