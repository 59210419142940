import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createCvSliceSelector, resetForm, setFormState, setShowViewCreateCvModal, validateCV } from './createCVSlice'
import PersonalInformation from './components/PersonalInformation';
import ProfessionalExperience from './components/ProfessionalExperience'
import Skills from './components/Skills';
import GeneralInformation from './components/GeneralInformation';
import ViewCVModal from '../modals/ViewCVModal';
import Profilemanagement from './components/Profilemanagement';
import { toast } from 'react-toastify';
import { EXTRACT_PROFILE_FROM_PDF } from '../../utils/requests/apiUrls';
import axios from 'axios';
import ExitCVModal from '../modals/ExitCVModal';
import { initialState, initialStateNewProExperience } from './createCvInitialState';
import { useNavigate } from 'react-router-dom';

export default function CreateCV() {
  const {
    formState,
    proExperienceForm,
    primarySkillTextInput,
    secondarySkillTextInput,
    languageTextInput,
    showViewCreateCvModal
  } = useSelector(createCvSliceSelector)

  const [openExitCvModal, setOpenExitCvModal] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const submitCv = async () => {
    dispatch(validateCV())
  }

  const resetFormState = () => {
    dispatch(resetForm())
  }

  const shouldOpenExitModal = () => {
    const hasCreateCvFormChange = JSON.stringify(initialState) !== JSON.stringify(formState)
    const hasAddProExperienceFormChanges = JSON.stringify(proExperienceForm) !== JSON.stringify(initialStateNewProExperience)
    const hasPrimarySkillsTextInputChanges = primarySkillTextInput !== ''
    const hasSecondarySkillsTextInputChanges = secondarySkillTextInput !== ''
    const hasLanguagseTextInputChanges = languageTextInput !== ''

    const hasChanges = hasCreateCvFormChange ||
      hasAddProExperienceFormChanges ||
      hasPrimarySkillsTextInputChanges ||
      hasSecondarySkillsTextInputChanges ||
      hasLanguagseTextInputChanges

    return hasChanges
  }


  const handleCloseButton = () => {
    const shouldOpenModal = shouldOpenExitModal()
    if (shouldOpenModal) {
      setOpenExitCvModal(true)
    } else {
      resetFormState()
      navigate('/cvs')
    }
  }

  // LinkedIn Pdf Extractor
  const handleFileChange = (file) => {
    sendLinkedInPdfFile(file)
  };

  const sendLinkedInPdfFile = async (file) => {
    const data = new FormData()
    data.append("file", file);

    await axios.post(EXTRACT_PROFILE_FROM_PDF, data)
      .then(res => {
        toast.success(<FormattedMessage id='upload.linkedInSuccess' />)
        dispatch(setFormState({ ...formState, ...res.data }))
      })
      .catch(er => {
        console.log(er)
        toast.error(<FormattedMessage id='error.somethingWentWrong' />)
      })
  }
  // LinkedIn Pdf Extractor

  return (
    <>
      <ViewCVModal showCv={showViewCreateCvModal} selectedCV={formState} setShowModal={setShowViewCreateCvModal} type={'createCv'} />
      <ExitCVModal isModalOpen={openExitCvModal} setIsModalOpen={setOpenExitCvModal} resetFormState={resetFormState} />
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Box component='form' sx={{ '& .MuiTextField-root': { m: 1 }, mt: 2, mb: 10, width: '60%' }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography><FormattedMessage id='personalInformation' /> *</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PersonalInformation />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography><FormattedMessage id='professionalExperience' /></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProfessionalExperience />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography><FormattedMessage id='skills' /> *</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Skills />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography><FormattedMessage id='generalInformation' /> *</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <GeneralInformation />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography><FormattedMessage id='profileManagement' /></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Profilemanagement />
            </AccordionDetails>
          </Accordion>
          <Box sx={{ mt: 5, display: 'flex', justifyContent: 'end' }}>
            <Box sx={{ display: 'flex', gap: 5, justifyContent: 'end' }}>
              <Button type="button" variant='contained' sx={{ bgcolor: 'primary.dark' }} onClick={handleCloseButton}>
                <FormattedMessage id='close' />
              </Button>
              <Button type="button" variant='contained' onClick={submitCv}>
                <FormattedMessage id='submitCv' />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}