import React, { useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import ApartmentIcon from '@mui/icons-material/Apartment'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkIcon from '@mui/icons-material/Work'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'
import { Divider } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { addNewProExp, createCvSliceSelector, saveEditedJob, setProExperienceForm, setEditableRowIndex, setEditJobForm } from '../createCVSlice'
import { useDispatch, useSelector } from 'react-redux'
import { globalSliceSelector } from '../../../app/slice'
import DeleteAddedJobModal from '../../modals/DeleteAddedJobModal'

function PersonalExperience() {
    const intl = useIntl()
    const dispatch = useDispatch()
    const { language } = useSelector(globalSliceSelector)
    const { formState, errorProExp, proExperienceForm, editableRowIndex, editJobForm, errorEditJobForm } = useSelector(createCvSliceSelector)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [jobToDelete, setJobToDelete] = useState({})
    const [indexOfJobToDelete, setIndexOfJobToDelete] = useState(null)

    function createDateFormat(date) {
        if (date) {
            return language === 'de' ? moment(date).format('DD.MM.yyyy') : moment(date).format('DD-MM-yyyy')
        }
        return <FormattedMessage id='currentDate' />
    }

    const handleNewProExpChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        dispatch(setProExperienceForm({ ...proExperienceForm, [key]: value }))
    }

    const handleDatePickersChange = (date, key, type) => {
        if (date._d instanceof Date && !isNaN(date._d)) {
            type === 'addJob' && dispatch(setProExperienceForm({ ...proExperienceForm, [key]: date }))
            type === 'editJob' && dispatch(setEditJobForm({ ...editJobForm, [key]: date }))
        }
    }
    const handleAddNewProExp = () => {
        dispatch(addNewProExp())
    }

    const handleDeleteProExp = (jobToDelete, index) => {
        setJobToDelete(jobToDelete)
        setShowDeleteModal(true)
        setIndexOfJobToDelete(index)
    }

    const editRow = (index) => {
        dispatch(setEditableRowIndex(index))
        dispatch(setEditJobForm(formState.proExperience[index]))
    }

    const cancelEditRow = () => {
        dispatch(setEditableRowIndex(null))
        dispatch(setEditJobForm({}))
    }

    const handleEditJobFormChange = (e) => {
        const key = e.target.name
        const value = e.target.value
        dispatch(setEditJobForm({ ...editJobForm, [key]: value }))
    }

    const handleSaveEditedJob = () => {
        dispatch(saveEditedJob())
    }

    // JSX Component
    function createEditJobComponent(index) {
        return (
            <>
                <Stack direction='column'>
                    <Stack direction='row' gap={5}>
                        <TextField
                            id='outlined-basic'
                            error={errorEditJobForm.companyName}
                            value={editJobForm.companyName}
                            onChange={handleEditJobFormChange}
                            name='companyName'
                            label={<FormattedMessage id='companyName' />}
                            variant='outlined'
                        />
                        <TextField
                            id='outlined-basic'
                            error={errorEditJobForm.title}
                            value={editJobForm.title}
                            onChange={handleEditJobFormChange}
                            name='title'
                            label={<FormattedMessage id='title' />}
                            variant='outlined'
                        />
                        <TextField
                            id='outlined-basic'
                            value={editJobForm.location}
                            onChange={handleEditJobFormChange}
                            name='location'
                            label={<FormattedMessage id='location' />}
                            variant='outlined'
                        />
                        <FormControl error={errorEditJobForm.workType} sx={{ m: 1, width: 195 }}>
                            <InputLabel id='demo-simple-select-autowidth-label'>
                                <FormattedMessage id='workType' />
                            </InputLabel>
                            <Select
                                labelId='demo-simple-select-autowidth-label'
                                id='demo-simple-select-autowidth'
                                autoWidth
                                label={<FormattedMessage id='workType' />}
                                name='workType'
                                value={editJobForm.workType}
                                onChange={handleEditJobFormChange}
                            >
                                <MenuItem value={'remote'}>Remote</MenuItem>
                                <MenuItem value={'hybrid'}>Hybrid</MenuItem>
                                <MenuItem value={'on-site'}>{<FormattedMessage id='on-site' />}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            id='outlined-basic'
                            error={errorEditJobForm.companyType}
                            value={editJobForm.companyType}
                            onChange={handleEditJobFormChange}
                            name='companyType'
                            label={<FormattedMessage id='companyType' />}
                            variant='outlined'
                        />
                    </Stack>
                    <Stack direction='row' sx={{ justifyContent: 'center' }}>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        disableFuture
                                        maxDate={editJobForm.end && moment(editJobForm.end).subtract(1, 'days')}
                                        sx={{ width: 170 }}
                                        label={<FormattedMessage id='startDate' />}
                                        name='start'
                                        views={['month', 'year', 'day']}
                                        value={moment(editJobForm.start)}
                                        onChange={(date) => { handleDatePickersChange(date, 'start', 'editJob') }}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        disableFuture
                                        minDate={editJobForm.start && moment(editJobForm.start).add(1, 'days')}
                                        sx={{ width: 180 }}
                                        label={<FormattedMessage id='endDate' />}
                                        name='end'
                                        views={['month', 'year', 'day']}
                                        value={moment(editJobForm.end)}
                                        onChange={(date) => handleDatePickersChange(date, 'end', 'editJob')}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Stack>
                    <Box>
                        <TextField
                            fullWidth
                            id='outlined-basic'
                            label={<FormattedMessage id='description' />}
                            name='description'
                            variant='outlined'
                            multiline
                            minRows={4}
                            maxRows={10}
                            value={editJobForm.description}
                            onChange={handleEditJobFormChange}
                            placeholder={intl.formatMessage({ id: 'description' })}
                        />
                    </Box>
                </Stack>
                <Stack direction='row' sx={{ justifyContent: 'center', mt: 5, mb: 3, gap: 5 }}>
                    <Button variant='contained' onClick={() => handleSaveEditedJob()}>
                        <FormattedMessage id="save" />
                    </Button>
                    <Button variant='contained' onClick={cancelEditRow}>
                        <FormattedMessage id="cancel" />
                    </Button>
                </Stack>
            </>
        )
    }

    // JSX Component
    function createProfessionalExperienceAccordion() {
        return (
            <div style={{ gap: 10 }} >
                {formState.proExperience.map((exp, index) => (
                    <Accordion key={index}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{exp.companyName}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {editableRowIndex === index && createEditJobComponent(exp, index)}
                            {editableRowIndex !== index && createJobDetailsComponent(exp, index)}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        )
    }

    // JSX Component
    function createJobDetailsComponent(exp, index) {
        const listItemFlexColumnStyles = { display: 'flex', flexDirection: 'column', textAlign: 'center' }
        const avatarCenterStyles = { display: 'flex', flexDirection: 'column', alignItems: 'center' }
        const secondaryTextToShow = exp.workType === 'remote' ? 'Remote'
            : exp.workType === 'hybrid' ? 'Hybrid'
                : <FormattedMessage id='on-site' />
        return (
            <>
                <List key={index} sx={{ display: 'flex', bgcolor: 'background.paper' }}>
                    <ListItem sx={listItemFlexColumnStyles}>
                        <ListItemAvatar sx={avatarCenterStyles}>
                            <Avatar >
                                <ApartmentIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<FormattedMessage id='companyName' />}
                            secondary={exp.companyName}
                        />
                    </ListItem>
                    <ListItem sx={listItemFlexColumnStyles}>
                        <ListItemAvatar sx={avatarCenterStyles}>
                            <Avatar>
                                <WorkIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<FormattedMessage id='title' />} secondary={exp.title} />
                    </ListItem>
                    <ListItem sx={listItemFlexColumnStyles}>
                        <ListItemAvatar sx={avatarCenterStyles}>
                            <Avatar>
                                <LocationOnIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={<FormattedMessage id='location' />} secondary={exp.location} />
                    </ListItem>
                    <ListItem sx={listItemFlexColumnStyles}>
                        <ListItemAvatar sx={avatarCenterStyles}>
                            <Avatar>
                                <ApartmentIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<FormattedMessage id='companyType' />}
                            secondary={exp.companyType}
                        />
                    </ListItem>
                    <ListItem sx={listItemFlexColumnStyles}>
                        <ListItemAvatar sx={avatarCenterStyles}>
                            <Avatar>
                                <WorkHistoryIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<FormattedMessage id='workType' />}
                            secondary={secondaryTextToShow}
                        />
                    </ListItem>
                    <ListItem sx={listItemFlexColumnStyles}>
                        <ListItemAvatar sx={avatarCenterStyles}>
                            <Avatar>
                                <CalendarMonthIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<FormattedMessage id='startDate' />}
                            secondary={createDateFormat(exp.start)}
                        />
                    </ListItem>
                    <ListItem sx={listItemFlexColumnStyles}>
                        <ListItemAvatar sx={avatarCenterStyles}>
                            <Avatar>
                                <CalendarMonthIcon />
                            </Avatar>
                        </ListItemAvatar >
                        <ListItemText
                            primary={<FormattedMessage id='endDate' />}
                            secondary={createDateFormat(exp.end)}
                        />
                    </ListItem>
                </List>
                <List key={index} sx={{ display: 'flex', bgcolor: 'background.paper' }}>
                    <ListItem sx={listItemFlexColumnStyles}>
                        <ListItemAvatar sx={avatarCenterStyles}>
                            <Avatar>
                                <ApartmentIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<FormattedMessage id='description' />}
                            secondary={exp.description}
                        />
                    </ListItem>
                </List>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Button sx={{ mr: 2 }} onClick={() => handleDeleteProExp(exp, index)}><FormattedMessage id='delete' />&nbsp;<DeleteForeverIcon /></Button>
                    <Button sx={{ ml: 2 }} onClick={() => editRow(index)}><FormattedMessage id='edit' />&nbsp;<ModeEditIcon /></Button>
                </Box>
            </>
        )
    }

    return (
        <>
            <DeleteAddedJobModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} jobToDelete={jobToDelete} indexOfJobToDelete={indexOfJobToDelete} type='createCV' />
            <Stack direction='column' gap={5}>
                <Box>
                    <Box>
                        <TextField
                            required
                            id='outlined-basic'
                            error={errorProExp.companyName}
                            value={proExperienceForm.companyName}
                            onChange={handleNewProExpChange}
                            name='companyName'
                            label={<FormattedMessage id='companyName' />}
                            variant='outlined'
                        />
                        <TextField
                            required
                            id='outlined-basic'
                            error={errorProExp.title}
                            value={proExperienceForm.title}
                            onChange={handleNewProExpChange}
                            name='title'
                            label={<FormattedMessage id='title' />}
                            variant='outlined'
                        />
                        <TextField
                            id='outlined-basic'
                            value={proExperienceForm.location}
                            onChange={handleNewProExpChange}
                            name='location'
                            label={<FormattedMessage id='location' />}
                            variant='outlined'
                        />
                    </Box>
                    <div>
                        <FormControl sx={{ m: 1, width: 195 }}>
                            <InputLabel id='demo-simple-select-autowidth-label'>
                                <FormattedMessage id='workType' />
                            </InputLabel>
                            <Select
                                labelId='demo-simple-select-autowidth-label'
                                id='demo-simple-select-autowidth'
                                autoWidth
                                label={<FormattedMessage id='workType' />}
                                name='workType'
                                value={proExperienceForm?.workType}
                                onChange={handleNewProExpChange}
                            >
                                <MenuItem value={'remote'}>Remote</MenuItem>
                                <MenuItem value={'hybrid'}>Hybrid</MenuItem>
                                <MenuItem value={'on-site'}>{<FormattedMessage id='on-site' />}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            required
                            id='outlined-basic'
                            error={errorProExp.companyType}
                            value={proExperienceForm.companyType}
                            onChange={handleNewProExpChange}
                            name='companyType'
                            label={<FormattedMessage id='companyType' />}
                            variant='outlined'
                        />
                    </div>
                    <Stack direction='row'>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        disableFuture
                                        maxDate={proExperienceForm.end && moment(proExperienceForm.end).subtract(1, 'days')}
                                        sx={{ width: 170 }}
                                        label={intl.formatMessage({ id: 'startDate' }) + '*'}
                                        name='start'
                                        views={['month', 'year', 'day']}
                                        value={proExperienceForm.start}
                                        onChange={(date) => { handleDatePickersChange(date, 'start', 'addJob') }}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={language}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        disableFuture
                                        minDate={proExperienceForm.start && moment(proExperienceForm.start).add(1, 'days')}
                                        sx={{ width: 180 }}
                                        label={intl.formatMessage({ id: 'endDate' })}
                                        name='end'
                                        views={['month', 'year', 'day']}
                                        value={proExperienceForm.end}
                                        onChange={(date) => handleDatePickersChange(date, 'end', 'addJob')}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </Stack>
                    <Box>
                        <TextField
                            fullWidth
                            id='outlined-basic'
                            label={<FormattedMessage id='description' />}
                            name='description'
                            variant='outlined'
                            multiline
                            minRows={4}
                            maxRows={10}
                            value={proExperienceForm.description}
                            onChange={handleNewProExpChange}
                            placeholder={intl.formatMessage({ id: 'description' })}
                        />
                    </Box>
                    <Box sx={{ ml: 1.2, mt: 3, mb: 1, display: 'flex', alignItems: 'start', gap: 2 }}>
                        <Button variant='contained' onClick={handleAddNewProExp}>
                            <FormattedMessage id='addJob' />
                        </Button>
                    </Box>
                </Box>
                {formState.proExperience.length > 0 && <Divider />}
                <Box>{createProfessionalExperienceAccordion()}</Box>
            </Stack>
        </>
    )
}

export default PersonalExperience