import React, { useEffect, useState } from "react";
import { Stack, Box, Button, Typography, Tooltip, styled, tooltipClasses } from '@mui/material/';
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCompanies, fetchSelectedCompany, companiesSliceSelector, setFilterOptions } from "./companiesSlice";
import { DataGrid } from '@mui/x-data-grid';
import useOnDependencyChange from '../../customHooks/useOnDependencyChange'
import EditCompanyModal from './EditCompanyModal'
import EditCreditsModal from "./EditCreditsModal";
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { FormattedMessage } from "react-intl";
import AddCardIcon from '@mui/icons-material/AddCard';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import TablePaginationActions from "../paginationActionsComponent/PaginationActions";
import moment from "moment";

export default function CompaniesPage() {
    const { companies, totalCompanies, filterOptions } = useSelector(companiesSliceSelector);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openCreditsModal, setOpenCreditsModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (companies.length === 0) {
            dispatch(fetchAllCompanies());
        }
    })

    useOnDependencyChange(() => {
        dispatch(fetchAllCompanies());
    }, [filterOptions]);

    const handlePaginationModelChange = (e) => {
        dispatch(setFilterOptions({ key: 'page', value: e.page }))
        dispatch(setFilterOptions({ key: 'pageSize', value: e.pageSize }))
    }

    const handleModalOpen = (id, modalType) => {
        dispatch(fetchSelectedCompany(id));
        modalType === 'edit' ? setOpenEditModal(true) : setOpenCreditsModal(true);
    };

    const closeModal = (data) => {
        //No need to check which modal to close just pass false to all
        setOpenEditModal(data)
        setOpenCreditsModal(data)
    };

    const columns = [
        { field: 'customerNumber', headerName: <FormattedMessage id="customerNumAbbr" />, width: 100, filterable: false, headerClassName: 'datagrid-theme' },
        { field: 'companyName', headerName: <FormattedMessage id="companyName" />, width: 200, filterable: false, headerClassName: 'datagrid-theme' },
        {
            field: 'credits', headerName: 'Credits', width: 100, filterable: false, headerClassName: 'datagrid-theme',
            headerAlign: 'center',
            align: 'center',
        },
        { field: 'email', headerName: 'Email', width: 250, filterable: false, headerClassName: 'datagrid-theme' },
        { field: 'phoneNumber', headerName: <FormattedMessage id="phone" />, width: 150, filterable: false, headerClassName: 'datagrid-theme' },
        {
            field: 'licenseRange',
            headerName: <FormattedMessage id="licensed" />,
            width: 100,
            filterable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'datagrid-theme',
            renderCell: (params) => {
                const licenseStart = params.row.licenseStart;
                const licenseEnd = params.row.licenseEnd;
                const currentDate = moment();

                const isWithinRange =
                    moment(currentDate).isSameOrAfter(licenseStart, 'day') && moment(currentDate).isSameOrBefore(licenseEnd, 'day');

                return (
                    <div>
                        {isWithinRange && licenseStart !== undefined ? (
                            <DoneIcon style={{ color: '#15CF02' }} />
                        ) : (
                            <CloseRoundedIcon color="error" />
                        )}
                    </div>
                );
            },
        },
        {
            field: 'verified',
            headerName: <FormattedMessage id="verified" />,
            width: 100,
            filterable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'datagrid-theme',
            renderCell: (params) => (
                <div>
                    {params.value === true ? <DoneIcon style={{ color: '#15CF02' }} /> : <CloseRoundedIcon color="error" />}
                </div>
            ),
        },
        {
            headerName: <FormattedMessage id="action" />,
            width: 150,
            filterable: false,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            headerClassName: 'datagrid-theme',
            renderCell: (params) => (
                <Stack direction={'row'} display={'flex'} justifyContent={'space-evenly'}>
                    <Button
                        sx={{ m: 0.5, minWidth: 30 }}
                        variant="outlined"
                        onClick={() => handleModalOpen(params.row.id, 'edit')}
                    >
                        <EditIcon />
                    </Button>
                    <Button
                        sx={{ m: 0.5, minWidth: 30 }}
                        variant="outlined"
                        onClick={() => handleModalOpen(params.row.id, 'credits')}
                    >
                        <AddCardIcon />
                    </Button>
                </Stack>
            ),
        },
    ];

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: '200px', // Make sure to use quotes around the value
            fontSize: '13px', // Use quotes around the value
        },
    });

    return (
        <Stack mt={3} display={'flex'} alignItems={'center'}>
            <Box width={'max-content'} >
                <Typography variant='h5' sx={{ mb: 3 }}><FormattedMessage id='companies' /></Typography>
                <Stack mt={2} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'} >
                    <Box sx={{
                        height: [400, 400, 400, 475, 700], width: [600, 600, 950, 'max-content', 'max-content'], '& .datagrid-theme': {
                            backgroundColor: 'primary.main',
                            color: "white",
                            '& .MuiDataGrid-sortIcon': {
                                color: 'white'
                            }
                        }
                    }}>
                        <DataGrid
                            rows={companies}
                            columns={columns}
                            sortingMode="server"
                            paginationMode='server'
                            sortable={false}
                            onPaginationModelChange={(e) => handlePaginationModelChange(e)}
                            onRowDoubleClick={(e) => handleModalOpen(e.id, 'edit')}
                            paginationModel={{ page: filterOptions.page, pageSize: filterOptions.pageSize }}
                            pageSizeOptions={[10, 15, 20, 25]}
                            sortingOrder={['asc', 'desc']}
                            rowCount={totalCompanies}
                            disableRowSelectionOnClick
                            slotProps={{
                                pagination: {
                                    ActionsComponent: (props) => (
                                        <TablePaginationActions
                                            page={filterOptions.page}
                                            pageSize={filterOptions.pageSize}
                                            totalCount={totalCompanies}
                                            setFilterOptions={setFilterOptions}
                                            {...props}
                                        />
                                    ),
                                }
                            }}
                        />
                    </Box>
                </Stack>
            </Box>
            <EditCompanyModal open={openEditModal} close={closeModal} />
            <EditCreditsModal open={openCreditsModal} close={closeModal} />
        </Stack>
    )
}