import { request } from './request'
import { SIGN_IN, GET_COMPANY, CREATE_ADMIN, UPDATE_ADMIN, RESET_ADMIN_PASS, GET_COMPANIES_NAMES, EDIT_COMPANY_INFO, GET_COMPANIES_BY_ID, GET_ALL_CVS, EDIT_COMPANY_CREDITS, GET_ALL_COMPANIES, ADD_CREDITS, REMOVE_CREDITS, CREATE_CV, UPDATE_CV, GET_ALL_ADMINS, GET_ADMIN_BY_ID, GET_CVS_BY_IDS, EXTRACT_PROFILE_FROM_PDF, TOKEN_AUTH, FORGOT_PASSWORD, RESET_PASSWORD, DELETE_ADMIN, DELETE_COMPANY, DELETE_CV, GET_SORTED_CV_FOR_ADMIN, GET_ALL_PACKAGES, GET_PACKAGE, CREATE_PACKAGE, UPDATE_PACKAGE, DELETE_PACKAGE } from './apiUrls'

export const signInCall = async ({ email, password }) => {
  const queryParams = { email, password }
  const requestOptions = {
    url: SIGN_IN,
    method: 'POST',
    // token,
    body: queryParams,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const authWithToken = async () => {
  const requestOptions = {
    url: TOKEN_AUTH,
    method: 'POST',
    // token,
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const forgotPasswordCall = async (data) => {
  const requestOptions = {
    url: FORGOT_PASSWORD,
    method: 'POST',
    // token,
    body: { email: data },
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const resetAdminPassword = async (data) => {
  const requestOptions = {
    url: RESET_PASSWORD,
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getCompaniesNames = async () => {
  const requestOptions = {
    url: GET_COMPANIES_NAMES,
    method: 'GET',
    // token,
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getCompanyById = async (id) => {
  const requestOptions = {
    url: GET_COMPANY(id),
    method: 'GET',
    // token,
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const updateCompanyInfo = async (data) => {
  const requestOptions = {
    url: EDIT_COMPANY_INFO(data._id),
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const addCompanyCredits = async (data) => {
  const requestOptions = {
    url: ADD_CREDITS(data.id),
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const removeCompanyCredits = async (data) => {
  const requestOptions = {
    url: REMOVE_CREDITS(data.id),
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getCompaniesById = async (companysIds) => {
  const requestOptions = {
    url: GET_COMPANIES_BY_ID,
    method: 'POST',
    // token,
    body: [companysIds],
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getAllCVs = async (filter) => {
  const requestOptions = {
    url: GET_ALL_CVS,
    method: 'POST',
    body: filter,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getAllCompanies = async (filter) => {
  const requestOptions = {
    url: GET_ALL_COMPANIES,
    method: 'POST',
    body: filter,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getAllAdmins = async (filter) => {
  const requestOptions = {
    url: GET_ALL_ADMINS,
    method: 'POST',
    body: filter,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getAdminById = async (id) => {
  const requestOptions = {
    url: GET_ADMIN_BY_ID(id),
    method: 'GET',
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
    console.log('response', response)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const updateAdminInfo = async (data) => {
  const requestOptions = {
    url: UPDATE_ADMIN,
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const resetSuperAdminPassword = async (data) => {
  const requestOptions = {
    url: RESET_ADMIN_PASS,
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const createAdmin = async (data) => {
  const requestOptions = {
    url: CREATE_ADMIN,
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const editCompanyCredits = async ({ id, credits }) => {
  const requestOptions = {
    url: EDIT_COMPANY_CREDITS,
    method: 'POST',
    body: { id, credits },
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    // if (err.response.data) {
    //   response = err.response.data
    // }
    console.log(err)
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const createCVCall = async (cv) => {
  const requestOptions = {
    url: CREATE_CV,
    method: 'POST',
    body: cv,
  }
  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const updateCvCall = async (cvId, cv) => {
  const requestOptions = {
    url: UPDATE_CV(cvId),
    method: 'PUT',
    body: cv,
  }
  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getSortedCvForAdminCall = async (cvID) => {
  const requestOptions = {
    url: GET_SORTED_CV_FOR_ADMIN(cvID),
    method: 'GET',
  }
  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getCvsByIdsCall = async (cvIDs) => {
  const requestOptions = {
    url: GET_CVS_BY_IDS,
    method: 'POST',
    body: { cvIDs: [cvIDs] }
  }
  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const extractProfileFromPdfCall = async (file) => {
  const requestOptions = {
    url: EXTRACT_PROFILE_FROM_PDF,
    method: 'POST',
    body: file
  }
  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const deleteAdminCall = async (id) => {
  const requestOptions = {
    url: DELETE_ADMIN(id),
    method: 'DELETE',
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const deleteCompanyCall = async (id) => {
  const requestOptions = {
    url: DELETE_COMPANY(id),
    method: 'DELETE',
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const deleteCVCall = async (data) => {
  const requestOptions = {
    url: DELETE_CV,
    method: 'POST',
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getAllPackages = async () => {
  const requestOptions = {
    url: GET_ALL_PACKAGES,
    method: 'GET',
    // token,
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const getPackageById = async (id) => {
  const requestOptions = {
    url: GET_PACKAGE(id),
    method: 'GET',
    // token,
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const createPackage = async (data) => {
  const requestOptions = {
    url: CREATE_PACKAGE,
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const updatePackage = async (data) => {
  const requestOptions = {
    url: UPDATE_PACKAGE,
    method: 'POST',
    // token,
    body: data,
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

export const deletePackageCall = async (id) => {
  const requestOptions = {
    url: DELETE_PACKAGE(id),
    method: 'DELETE',
    body: {},
  }

  let response
  try {
    response = await request(requestOptions)
  } catch (err) {
    if (err.response.data) {
      response = err.response.data
    }
  }

  return response
}

