import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

export default function TablePaginationActions(props) {
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleFirstPageButtonClick = () => {
        dispatch(props.setFilterOptions({ key: 'page', value: 0 }))
    };

    const handleBackButtonClick = () => {
        dispatch(props.setFilterOptions({ key: 'page', value: (props.page - 1) }))
    };

    const handleNextButtonClick = () => {
        dispatch(props.setFilterOptions({ key: 'page', value: (props.page + 1) }))
    };

    const handleLastPageButtonClick = () => {
        dispatch(props.setFilterOptions({ key: 'page', value: Math.max(0, Math.ceil(props.totalCount / props.pageSize) - 1) }))
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                disabled={props.page === 0}
                onClick={handleFirstPageButtonClick}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                disabled={props.page === 0}
                onClick={handleBackButtonClick}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                disabled={props.page >= Math.ceil(props.totalCount / props.pageSize) - 1}
                onClick={handleNextButtonClick}

                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                disabled={props.page >= Math.ceil(props.totalCount / props.pageSize) - 1}
                onClick={handleLastPageButtonClick}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}