import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authSliceSelector } from '../features/authComponent/authSlice';

export const AuthGuard = ({ children }) => {
  const { authenticated } = useSelector(authSliceSelector);
  const isAuth = authenticated;
  if (!isAuth) {
    return <Navigate to='/sign-in' replace />;
  }

  return children;
};

export const GuestGuard = ({ children }) => {
  const { authenticated } = useSelector(authSliceSelector);
  const isAuth = authenticated;

  if (isAuth) {
    return <Navigate to='/' replace />;
  }
  
  return children;
};