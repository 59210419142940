import { takeLatest, put, call, select } from 'redux-saga/effects'
import {
  createCvSliceSelector,
  setError,
  setErrorProExp,
  setFormState,
  setPrimarySkillTextInput,
  addSecondarySkill,
  deleteSecondarySkill,
  setSecondarySkillTextInput,
  addlanguage,
  deleteLanguage,
  setLanguageTextInput,
  setProExperienceForm,
  saveEditedJob,
  setEditableRowIndex,
  setErrorEditJobForm,
  validateCV,
  setShowViewCreateCvModal,
  resetForm
} from './createCVSlice'
import { toast } from 'react-toastify'
import { createCVCall } from '../../utils/requests/apiCalls'
import { createCV, addNewProExp, deleteProExp, addPrimarySkill, deletePrimarySkill } from './createCVSlice'
import { FormattedMessage } from 'react-intl'
import { initialState, initialStateNewProExperience } from './createCvInitialState'
import { cvsSliceSelector, setCvs } from '../cvsComponent/CVsSlice'

function* validateCVSaga() {
  // --- CV Validation ---
  const { formState } = yield select(createCvSliceSelector)
  const newError = {
    firstName: !formState.firstName,
    lastName: !formState.lastName,
    email: !formState.email,
    locationCountry: !formState.location.country,
    locationCity: !formState.location.city,
    phoneNumber: !formState.phoneNumber,
    workModel: !formState.workModel,
    yearsOfExperience: isNaN(formState.yearsOfExperience),
    jobTitle: !formState.jobTitle,
    salaryRatePerHour: isNaN(formState.salaryRatePerHour),
    level: !formState.level,
    startInWeeks: isNaN(formState.startInWeeks),
    teaserCompanySize: !formState.teaser.companySize,
    willToTravel: !(typeof formState.willToTravel === 'boolean'),
    readyToRelocate: !(typeof formState.readyToRelocate === 'boolean'),
    primarySkills: formState.primarySkills.length === 0,
    languages: formState.languages.length === 0,
    contractType: !formState.contractType,
    price: isNaN(formState.price),
  }
  const isLastContactInFuture = formState.lastContact !== null && new Date(formState.lastContact) > new Date()

  newError.primarySkills && toast.error(<FormattedMessage id='createCv.error.emptyPrimarySkills' />)
  newError.languages && toast.error(<FormattedMessage id='createCv.error.emptyLanguages' />)

  yield put(setError(newError))

  if (Object.entries(newError).find(pair => pair[1]) || isLastContactInFuture) {
    Object.entries(newError).find(pair => pair[1]) && toast.error(<FormattedMessage id='createCv.error.allFieldsFilled' />)
    isLastContactInFuture && toast.error(<FormattedMessage id='lastContact.error.dateIsInFuture' />)
    return;
  }
  // --- CV Validation ---
  yield put(setShowViewCreateCvModal(true))
}

function* createCVSaga(action) {
  const { formState } = yield select(createCvSliceSelector)
  const { cvs } = yield select(cvsSliceSelector)
  const finalCV = {
    firstName: formState.firstName,
    lastName: formState.lastName,
    email: formState.email,
    location: formState.location,
    phoneNumber: formState.phoneNumber,
    workModel: formState.workModel,
    yearsOfExperience: Number(formState.yearsOfExperience),
    jobTitle: formState.jobTitle,
    salaryRatePerHour: Number(formState.salaryRatePerHour),
    level: formState.level,
    startInWeeks: Number(formState.startInWeeks),
    teaser: formState.teaser,
    willToTravel: formState.willToTravel,
    readyToRelocate: formState.readyToRelocate,
    proExperience: formState.proExperience,
    primarySkills: formState.primarySkills,
    secondarySkills: formState.secondarySkills,
    languages: formState.languages,
    contractType: formState.contractType,
    price: Number(formState.price),
    lastContact: formState.lastContact ? formState.lastContact : null,
  }

  try {
    const res = yield call(createCVCall, finalCV)
    if (res.statusCode === 201) {
      toast.success(<FormattedMessage id='createCv.created' />)
      yield put(setPrimarySkillTextInput(''))
      yield put(setSecondarySkillTextInput(''))
      yield put(setLanguageTextInput(''))
      yield put(setFormState(initialState))
      yield put(setProExperienceForm(initialStateNewProExperience))
      yield put(setShowViewCreateCvModal(false))
      yield put(setCvs([...cvs, res.data]))
      // action.payload.navigate('/cvs')
    }
  } catch (error) {
    console.log('error', error)
  }
}

function* addNewProExpSaga() {
  const { formState, proExperienceForm } = yield select(createCvSliceSelector)

  // --- Validate
  const newError = {
    companyName: !proExperienceForm.companyName,
    start: !proExperienceForm.start,
    title: !proExperienceForm.title,
    companyType: !proExperienceForm.companyType,
  }

  yield put(setErrorProExp(newError))
  const hasNewError = Object.entries(newError).find(pair => pair[1])
  const isStartDateBiggerThanEndDate = proExperienceForm.end ? new Date(proExperienceForm.start) > new Date(proExperienceForm.end) : false
  const isStartDateInTheFuture = new Date(proExperienceForm.start) > new Date()
  const isEndDateInTheFuture = new Date(proExperienceForm.end) > new Date()

  if (hasNewError || isStartDateBiggerThanEndDate || isStartDateInTheFuture || isEndDateInTheFuture) {
    hasNewError && toast.error(<FormattedMessage id='createCv.error.addProExperience' />)
    isStartDateBiggerThanEndDate && toast.error(<FormattedMessage id='createCv.error.startDateBiggerThanEndDate' />)
    isStartDateInTheFuture && toast.error(<FormattedMessage id='createCv.error.startDateInFuture' />)
    isEndDateInTheFuture && toast.error(<FormattedMessage id='createCv.error.endDateInFuture' />)
    return
  }
  // --- Validate
  yield put(setFormState({ ...formState, proExperience: [...formState.proExperience, proExperienceForm] }))
  yield put((setProExperienceForm(initialStateNewProExperience)))
}

function* deleteProExpSaga(action) {
  const { formState } = yield select(createCvSliceSelector)
  const index = action.payload

  const newArray = formState.proExperience.filter((exp, i) => index !== i)
  yield put(setFormState({ ...formState, proExperience: newArray }))
}

function* addPrimarySkillSaga() {
  const { primarySkillTextInput, formState, error } = yield select(createCvSliceSelector)
  if (!primarySkillTextInput) {
    toast.warning(<FormattedMessage id='createCv.error.addEmptyPrimarySkill' />)
    return
  }

  if (error.primarySkills) {
    yield put(setError({ ...error, primarySkills: false }))
  }

  yield put(setFormState({ ...formState, primarySkills: [...formState.primarySkills, primarySkillTextInput] }))
  yield put(setPrimarySkillTextInput(''))
}

function* deletePrimarySkillSaga(action) {
  const index = action.payload
  const { formState } = yield select(createCvSliceSelector)
  const newArray = formState.primarySkills.filter((exp, i) => index !== i)
  yield put(setFormState({ ...formState, primarySkills: [...newArray] }))
}

function* addSecondarySkillSaga() {
  const { secondarySkillTextInput, formState, error } = yield select(createCvSliceSelector)
  if (!secondarySkillTextInput) {
    toast.warning(<FormattedMessage id='createCv.error.addSecondarySkill' />)
    return
  }

  // This might not be needed anymore
  if (error.secondarySkills) {
    yield put(setError({ ...error, secondarySkills: false }))
  }

  yield put(setFormState({ ...formState, secondarySkills: [...formState.secondarySkills, secondarySkillTextInput] }))
  yield put(setSecondarySkillTextInput(''))
}

function* deleteSecondarySkillSaga(action) {
  const index = action.payload
  const { formState } = yield select(createCvSliceSelector)
  const newArray = formState.secondarySkills.filter((skill, i) => i !== index)
  yield put(setFormState({ ...formState, secondarySkills: [...newArray] }))
}

function* addLanguageSaga() {
  const { languageTextInput, formState, error } = yield select(createCvSliceSelector)
  if (!languageTextInput) {
    toast.warning(<FormattedMessage id='createCv.error.addEmptyLanguage' />)
    return
  }
  if (error.languages) {
    yield put(setError({ ...error, languages: false }))
  }

  yield put(setFormState({ ...formState, languages: [...formState.languages, languageTextInput] }))
  yield put(setLanguageTextInput(''))
}

function* deleteLanguageSaga(action) {
  const index = action.payload
  const { formState } = yield select(createCvSliceSelector)
  const newArray = formState.languages.filter((lan, i) => index !== i)
  yield put(setFormState({ ...formState, languages: [...newArray] }))
}

function* saveEditedJobSaga() {
  const { formState, editJobForm, editableRowIndex } = yield select(createCvSliceSelector)
  // -- Validate
  const newError = {
    companyName: !editJobForm.companyName,
    start: !editJobForm.start,
    title: !editJobForm.title,
    companyType: !editJobForm.companyType,
  }

  yield put(setErrorEditJobForm(newError))
  const hasNewError = Object.entries(newError).find(pair => pair[1])
  const isStartDateBiggerThanEndDate = editJobForm.end ? new Date(editJobForm.start) > new Date(editJobForm.end) : false
  const isStartDateInTheFuture = new Date(editJobForm.start) > new Date()
  const isEndDateInTheFuture = new Date(editJobForm.end) > new Date()

  if (hasNewError || isStartDateBiggerThanEndDate || isStartDateInTheFuture || isEndDateInTheFuture) {
    hasNewError && toast.error(<FormattedMessage id='createCv.error.addProExperience' />)
    isStartDateBiggerThanEndDate && toast.error(<FormattedMessage id='createCv.error.startDateBiggerThanEndDate' />)
    isStartDateInTheFuture && toast.error(<FormattedMessage id='createCv.error.startDateInFuture' />)
    isEndDateInTheFuture && toast.error(<FormattedMessage id='createCv.error.endDateInFuture' />)
    return
  }
  // -- Validate

  const newProExperienceArray = formState.proExperience.map((exp, i) => i === editableRowIndex ? editJobForm : exp)
  yield put(setEditableRowIndex(null))
  yield put(setFormState({ ...formState, proExperience: [...newProExperienceArray] }))
}

function* resetFormSaga() {
  yield put(setFormState(initialState))
  yield put(setProExperienceForm(initialStateNewProExperience))
  yield put(setPrimarySkillTextInput(''))
  yield put(setSecondarySkillTextInput(''))
  yield put(setLanguageTextInput(''))
}

export function* watchCreateCVSagaSaga() {
  yield takeLatest(validateCV.type, validateCVSaga)
  yield takeLatest(createCV.type, createCVSaga)
  yield takeLatest(addNewProExp.type, addNewProExpSaga)
  yield takeLatest(deleteProExp.type, deleteProExpSaga)
  yield takeLatest(addPrimarySkill.type, addPrimarySkillSaga)
  yield takeLatest(deletePrimarySkill.type, deletePrimarySkillSaga)
  yield takeLatest(addSecondarySkill.type, addSecondarySkillSaga)
  yield takeLatest(deleteSecondarySkill.type, deleteSecondarySkillSaga)
  yield takeLatest(addlanguage.type, addLanguageSaga)
  yield takeLatest(deleteLanguage.type, deleteLanguageSaga)
  yield takeLatest(saveEditedJob.type, saveEditedJobSaga)
  yield takeLatest(resetForm.type, resetFormSaga)
}
