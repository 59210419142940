import React from 'react'
import TextField from '@mui/material/TextField'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createCvSliceSelector } from '../createCVSlice'
import { setFormState } from '../createCVSlice'

function PersonalInformation() {
    const dispatch = useDispatch()
    const { formState, error } = useSelector(createCvSliceSelector)


    const handleCvInputs = (e) => {
        const key = e.target.name
        const value = e.target.value
        if (key === 'country' || key === 'city') {
            dispatch(setFormState({ ...formState, location: { ...formState.location, [key]: value, } }))
        } else if (key === 'phoneNumber') {
            // allow only numbers and these characters / , ()
            dispatch(setFormState({ ...formState, [key]: value.replace(/[^0-9/()+-]/g, '') }))
        }
        else {
            dispatch(setFormState({ ...formState, [key]: value }))
        }
    }

    return (
        <>
            <div>
                <TextField
                    required
                    id='outlined-basic'
                    error={error.firstName}
                    value={formState.firstName}
                    onChange={handleCvInputs}
                    name='firstName'
                    label={<FormattedMessage id='firstName' />}
                    variant='outlined'
                />
                <TextField
                    required
                    id='outlined-basic'
                    error={error.lastName}
                    value={formState.lastName}
                    onChange={handleCvInputs}
                    name='lastName'
                    label={<FormattedMessage id='lastName' />}
                    variant='outlined'
                />
                <TextField
                    required
                    id='outlined-basic'
                    error={error.email}
                    value={formState.email}
                    onChange={handleCvInputs}
                    name='email'
                    label='Email'
                    variant='outlined'
                    sx={{ width: 280 }}
                />
            </div>
            <div>
                <TextField
                    required
                    id='outlined-basic'
                    error={error.phoneNumber}
                    value={formState.phoneNumber}
                    onChange={handleCvInputs}
                    name='phoneNumber'
                    label={<FormattedMessage id='phonenumber' />}
                    variant='outlined'
                />
                <TextField
                    required
                    id='outlined-basic'
                    error={error.locationCountry}
                    value={formState.location.country}
                    onChange={handleCvInputs}
                    name='country'
                    label={<FormattedMessage id='country' />}
                    variant='outlined'
                />
                <TextField
                    required
                    id='outlined-basic'
                    error={error.locationCity}
                    value={formState.location.city}
                    onChange={handleCvInputs}
                    name='city'
                    label={<FormattedMessage id='city' />}
                    variant='outlined'
                />
            </div>
        </>
    )
}

export default PersonalInformation