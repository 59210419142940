import {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, TextField, Typography, Stack } from '@mui/material'
import Modal from '@mui/material/Modal'
import { FormattedMessage } from 'react-intl'
import { companiesSliceSelector, postCompanyCredits } from './companiesSlice'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

export default function EditCreditsModal({open, close}) {
  const dispatch = useDispatch()
  const { selectedCompany } = useSelector(companiesSliceSelector)
  const [credits, setCredits] = useState(0);

  const closeModal = () => {
    setCredits(0)
    //Pass false to parent state: openModal
    close(false);
  }
  
  const handleUpdateCredits = (operation) => {  
    const data = {
        operation,
        id: selectedCompany?._id,
        credits,
    };
    dispatch(postCompanyCredits(data));
};

const handleCreditsOnChange = (e) => {
    const re = /^[0-9\b]+$/; //allow only numbers

    if (e.target.value === '' || re.test(e.target.value)) {
        setCredits(Number(e.target.value))
    }
}

  return (
    <>
      <Modal
        open={open}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          > <CloseRoundedIcon
              onClick={closeModal}
              sx={{ color: 'grey', alignSelf: 'flex-end'}}
            />
            <Typography variant='h5'>
              <FormattedMessage id='editCredits' />
            </Typography>
            <Stack mt={2} textAlign={'center'}>
                    <Typography variant="body1" m={2}><FormattedMessage id='currentCredits' />: {selectedCompany?.credits}</Typography>
                    <TextField
                        id="outlined-helperText"
                        label="Credits"
                        value={credits}
                        onChange={(e) => handleCreditsOnChange(e)}
                    />
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" disabled={credits === 0} onClick={() => handleUpdateCredits('add')}><FormattedMessage id='addCredits' /></Button>
                        <Button variant="contained" disabled={selectedCompany?.credits === 0 || credits === 0 || selectedCompany?.credits < credits} onClick={() => handleUpdateCredits('remove')}><FormattedMessage id='removeCredits' /></Button>
                    </Stack>
                </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
