import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { cvsSliceSelector, getCvs, setFilterOptions } from './CVsSlice';
import { initialState } from '../createCVComponent/createCvInitialState';
import ViewCVModal from '../modals/ViewCVModal';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import TablePaginationActions from '../paginationActionsComponent/PaginationActions';
import useOnDependencyChange from '../../customHooks/useOnDependencyChange';
import { toast } from 'react-toastify';
import { getSortedCvForAdminCall } from '../../utils/requests/apiCalls';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

function CVsPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const { cvs, filterOptions, totalCvs } = useSelector(cvsSliceSelector)
  const [showModal, setShowModal] = useState(false)
  const [selectedCV, setSelectedCV] = useState(initialState)

  const viewCvOpenModal = async (cv) => {
    const res = await getSortedCvForAdminCall(cv._id)
    if (res.error === false) {
      setSelectedCV(res.data[0])
      setShowModal(true)
    } else {
      toast.error(intl.formatMessage({ id: 'error.somethingWentWrong' }))
    }
  }

  const handlePaginationModelChange = (e) => {
    dispatch(setFilterOptions({ key: 'page', value: e.page }))
    dispatch(setFilterOptions({ key: 'pageSize', value: e.pageSize }))
  }

  const handleEditCvOpen = (id) => {
    navigate(`/edit-cv/${id}`)
  };

  const renderLastContactColor = (date) => {
    if (date === null) return;

    const lastContactDate = new Date(date)

    const currentDate = new Date().setHours(0, 0, 0, 0);

    const timeDifference = currentDate - lastContactDate;

    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference <= 28) {  // 4 weeks
      return 'lastContact.green'
    } else if (daysDifference <= 42) { // 6 weeks
      return 'lastContact.yellow'
    } else if (daysDifference > 42) {
      return 'lastContact.red'
    }
  }

  const columns = [
    {
      field: 'lastContact',
      headerName: <ConnectWithoutContactIcon />,
      width: 60,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'datagrid-theme',
      renderCell: (params) => (
        <>
          {params.value && <Box sx={{ color: renderLastContactColor(params.value), fontSize: '40px', mt: '3.5px' }}>•</Box>}
          {!params.value && <Box sx={{ fontSize: '30px', mb: '3px' }}>-</Box>}
        </>
      ),
    },
    { field: 'firstName', headerName: <FormattedMessage id='firstName' />, width: 150, filterable: false, headerClassName: 'datagrid-theme' },
    { field: 'lastName', headerName: <FormattedMessage id='lastName' />, width: 150, filterable: false, headerClassName: 'datagrid-theme' },
    { field: 'email', headerName: 'Email', width: 210, filterable: false, headerClassName: 'datagrid-theme' },
    { field: 'phoneNumber', headerName: <FormattedMessage id='phonenumber' />, width: 160, filterable: false, headerClassName: 'datagrid-theme' },
    { field: 'jobTitle', headerName: <FormattedMessage id='jobTitle' />, width: 200, filterable: false, headerClassName: 'datagrid-theme' },
    {
      headerName: <FormattedMessage id="action" />,
      width: 150,
      filterable: false,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'datagrid-theme',
      renderCell: (params) => (
        <Stack direction={'row'} display={'flex'} justifyContent={'space-evenly'}>
          <Button
            sx={{ m: 0.5, minWidth: 30 }}
            variant="outlined"
            onClick={() => {
              navigate(`/edit-cv/${params.row._id}`)
            }}
          >
            <EditIcon />
          </Button>
          <Button
            sx={{ m: 0.5, minWidth: 30 }}
            variant="outlined"
            onClick={() => viewCvOpenModal(params.row)}
          >
            <RemoveRedEyeIcon />
          </Button>
        </Stack>
      ),
    },
  ];

  // const rowsWithRowNumber = cvs.map((cv, index) => ({ ...cv, id: index + 1 }));

  useEffect(() => {
    if (cvs.length === 0) {
      dispatch(getCvs());
    }
  }, [])

  useOnDependencyChange(() => {
    dispatch(getCvs());
  }, [filterOptions]);

  const cvsWithIdFielForTable = cvs.map(cv => ({ ...cv, id: cv._id }))

  return (
    <>
      <ViewCVModal showCv={showModal} selectedCV={selectedCV} setShowModal={setShowModal} type={'viewCv'} />
      <Stack mt={3} display={'flex'} alignItems={'center'}>
        <Box width={'max-content'}>
          <Typography variant='h5' sx={{ mb: 3 }}>CV's</Typography>
          <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}>
          </Stack>
          <Stack mt={2} display={'flex'} justifyContent={'center'} alignItems={'center'} textAlign={'center'} >
            <Box sx={{
              height: [400, 400, 400, 410, 650], width: [600, 600, 900, 'max-content', 'max-content'], '& .datagrid-theme': {
                backgroundColor: 'primary.main',
                color: "white"
              }
            }}>
              <DataGrid
                rows={cvsWithIdFielForTable}
                columns={columns}
                sortingMode="server"
                paginationMode='server'
                // leave below the handleEditCvOpen(e.row._id) like this for now
                onRowDoubleClick={(e) => handleEditCvOpen(e.row.id)}
                onPaginationModelChange={(e) => handlePaginationModelChange(e)}
                paginationModel={{ page: filterOptions.page, pageSize: filterOptions.pageSize }}
                pageSizeOptions={[10, 15, 20, 25]}
                rowCount={totalCvs}
                disableRowSelectionOnClick
                slotProps={{
                  pagination: {
                    ActionsComponent: (props) => (
                      <TablePaginationActions
                        page={filterOptions.page}
                        pageSize={filterOptions.pageSize}
                        totalCount={totalCvs}
                        setFilterOptions={setFilterOptions}
                        {...props}
                      />
                    ),
                  }
                }}
              />
            </Box>
          </Stack>
          <Button onClick={() => navigate('/create-cv')} variant="contained" mt={3} sx={{ float: 'right', mt: 2 }}><FormattedMessage id="createCV" /></Button>
        </Box>
      </Stack>
    </>
  )
}

export default CVsPage