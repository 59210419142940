import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationDialog({ title, message, messageValue, agreeCallBack, disagreeCallback, open }) {

    const handleAgree = async () => {
        agreeCallBack();
    }

    const handleDisagree = () => {
        disagreeCallback();
    };

    return (
        <Dialog
            sx={{ zIndex: 1500 }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleDisagree}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle><FormattedMessage id={title} /></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <Typography>
                        <FormattedMessage
                            id={message}
                            values={{ action: <span style={{ fontWeight: 'bold' }}>{messageValue}</span> }}
                        />
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDisagree}><FormattedMessage id="cancel" /></Button>
                <Button onClick={handleAgree} color='success'><FormattedMessage id="agree" /></Button>
            </DialogActions>
        </Dialog>
    );
}