import React, { useState } from 'react';
import { Button, Stack, TextField, Container, Typography, FormControl, InputLabel, Select, MenuItem, Modal } from '@mui/material';
import { useDispatch } from 'react-redux';
import { postAdminInfo } from './adminSlice';
import { FormattedMessage, useIntl } from 'react-intl';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

const CreateAdminModal = ({ open, close }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(postAdminInfo(formData))
    close(false);
  };

  const closeModal = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    })
    //Pass false to parent state: openModal
    close(false);
  }

  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Container component="main" maxWidth="xs" sx={style}>
        <Typography variant="h5" align="center" mb={3}>
          <FormattedMessage id="createAdminForm" />
        </Typography>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: "firstName" })}
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              m={2}
            />
            <TextField
              fullWidth
              label={intl.formatMessage({ id: "lastName" })}
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              p={2}
            />
            <TextField
              fullWidth
              label="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              m={2}
            />
            <TextField
              fullWidth
              label={intl.formatMessage({ id: "password" })}
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <Stack direction='row' spacing={3}>
              <Button variant="contained" color="success" fullWidth onClick={closeModal}>
                <FormattedMessage id="cancel" />
              </Button>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                <FormattedMessage id="save" />
              </Button>
            </Stack>
          </Stack>
        </form>
      </Container>
    </Modal>
  );
};

export default CreateAdminModal;
